"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateCallerInbound = exports.updatePhoneNumber = exports.updateCallStatus = exports.resetUAState = exports.newUAConnection = exports.CONNECT = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const jssip_1 = require("jssip");
const comunicationConstants_1 = require("../../constants/comunicationConstants");
const communicationBoxConstants_1 = require("./communicationBoxConstants");
const initialState = {
    ua: null,
    voipCallStatus: communicationBoxConstants_1.IDLE,
    phoneNumber: communicationBoxConstants_1.COUNTRY_CODE,
    isValidPhoneNumber: false,
    callerInbound: '',
};
exports.CONNECT = 'communication/connect';
const communicationSlice = toolkit_1.createSlice({
    name: 'communication',
    initialState,
    reducers: {
        newUAConnection: (state, { payload }) => {
            const socket = new jssip_1.WebSocketInterface(`wss://${comunicationConstants_1.SERVER_URL}:${comunicationConstants_1.SERVER_PORT}/ws`);
            const { voipUserId, voipPassword } = payload;
            const configuration = {
                sockets: [socket],
                uri: `sip:${voipUserId}@${comunicationConstants_1.SERVER_URL}`,
                password: voipPassword,
                authorizationUser: voipUserId,
                displayName: voipUserId,
                register: true,
                register_expires: 3600, // registrations will last an hour.
            };
            return { ...state, ua: new jssip_1.UA(configuration) };
        },
        resetUAState(state) {
            return {
                ...state,
                ua: null,
            };
        },
        updateCallStatus(state, { payload }) {
            return { ...state, voipCallStatus: payload.status };
        },
        updateCallerInbound(state, { payload }) {
            return { ...state, callerInbound: payload.callerInbound };
        },
        updatePhoneNumber(state, { payload }) {
            return { ...state, ...payload };
        },
    },
});
_a = communicationSlice.actions, exports.newUAConnection = _a.newUAConnection, exports.resetUAState = _a.resetUAState, exports.updateCallStatus = _a.updateCallStatus, exports.updatePhoneNumber = _a.updatePhoneNumber, exports.updateCallerInbound = _a.updateCallerInbound;
exports.default = communicationSlice.reducer;
