"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.handle401Error = exports.setAnalyticsUserUtil = exports.loggerUtil = void 0;
const Sentry = __importStar(require("@sentry/react"));
const analytics_1 = require("./analytics");
const authSlice_1 = require("../features/auth/authSlice");
const constants_1 = require("../constants/constants");
const network_1 = require("./network");
/**
 * Auxiliary function of the actions we want to log.
 */
const shouldLogActionType = (actionType) => {
    // Ignore all persist actions
    if (actionType.startsWith('persist/')) {
        return false;
    }
    return true;
};
/**
 * Customize communication state to avoiding sending unrequired fields and properties(_registrator) that lead to infinite recursion for mixpanel
 */
const customizeCommunicationState = (ua) => ({
    C: ua.C,
    contact: ua.contact,
    configuration: {
        authorization_user: ua.get('authorization_user'),
        contact_uri: ua.get('contact_uri'),
        uri: ua.get('uri'),
        registrar_server: ua.get('registrar_server'),
        instance_id: ua.get('instance_id'),
    },
    status: ua.status,
});
// creates the extraProperties sent to mixpanel
const buildLoggingExtraProperties = (previousState, nextState, payload) => {
    const extraProperties = {
        previousState,
        nextState,
        payload,
    };
    // All modifications on state here.
    if (previousState.communication.ua) {
        extraProperties.previousState.communication = {
            ...extraProperties.nextState.communication,
            ua: customizeCommunicationState(previousState.communication.ua),
        };
    }
    if (nextState.communication.ua) {
        extraProperties.nextState.communication = {
            ...extraProperties.nextState.communication,
            ua: customizeCommunicationState(nextState.communication.ua),
        };
    }
    return extraProperties;
};
/**
 * Logs all actions and states after they are dispatched.
 */
const loggerUtil = () => (store) => (next) => (action) => {
    if (shouldLogActionType(action.type)) {
        const previousState = store.getState();
        const result = next(action);
        const nextState = store.getState();
        const { payload } = action;
        const extraProperties = buildLoggingExtraProperties({ ...previousState }, { ...nextState }, { ...payload });
        /**
         * We are trying to replace '/' with an underscore.
         * The 'replaceAll' function formerly used does not run on all browsers, especially old browsers.
         *
         * The 'replace' function is an older function that works with old browsers.
         * The regex matches the '/' character and replaces all matches with an underscore.
         * The 'g' means Global, and causes the replace function call to replace all matches of '/' with '_'.
         */
        const eventName = action.type.replace(/[/]/g, '_');
        if (action.meta && action.meta.requestStatus) {
            if ([constants_1.FULFILLED_RESPONSE_CONSTANT, constants_1.REJECTED_RESPONSE_CONSTANT].includes(action.meta.requestStatus)) {
                analytics_1.logEvent(`api_${eventName}`, extraProperties);
            }
        }
        analytics_1.logEvent(`redux_${eventName}`, extraProperties);
        return result;
    }
    return next(action);
};
exports.loggerUtil = loggerUtil;
/**
 * Sets the user used in sentry and mixpanel
 */
const setAnalyticsUserUtil = (mixpanel) => (store) => (next) => (action) => {
    const result = next(action);
    if (shouldLogActionType(action.type)) {
        const state = store.getState();
        const { user } = state.auth;
        // user email is the the user id used in mixpanel
        if (!user && action.type === authSlice_1.loginActionType) {
            // Identify a user with a unique ID to track user activity across devices,
            // tie a user to their events, and create a user profile.
            // If you never call this method, unique visitors are tracked using
            // a UUID that generates the first time they visit the site.
            mixpanel.identify(user.email);
            // TODO add platform and partner, change typing of mixpanel
            Sentry.setUser({ email: user.email, id: user.id });
        }
        if (action.type === 'auth/logout') {
            mixpanel.reset();
            Sentry.setUser(null);
        }
    }
    return result;
};
exports.setAnalyticsUserUtil = setAnalyticsUserUtil;
/**
 * A Redux middleware that intercepts actions with a 401 error code and dispatches an action to
 * handle invalid session tokens.
 *
 * @param api - The Redux store API that contains the `dispatch` method.
 *              This is used to dispatch the `invalidSessionToken` action in case of a 401 error code.
 */
const handle401Error = (api) => (next) => (action) => {
    // If the error code is 401, dispatch an action to handle invalid session tokens (Redirect users to the login screen).
    if (action.payload && action.payload.code === network_1.statusCode401Unauthorized) {
        api.dispatch(authSlice_1.invalidSessionToken());
    }
    // Call the next middleware in the chain, or the reducer if this is the last middleware
    return next(action);
};
exports.handle401Error = handle401Error;
