"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const usePrevious = (value) => {
    const ref = react_1.useRef();
    react_1.useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
exports.default = usePrevious;
