"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSelectedCallRecordIndex = exports.resetAuditState = exports.resetCallRecordState = exports.getCallDetailRecordAudit = exports.auditCallDetailRecord = exports.getCallDetailRecords = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_native_1 = require("react-native");
const constants_1 = require("../../constants/constants");
const typesConstants_1 = require("../../constants/typesConstants");
const network_1 = require("../../utils/network");
const utils_1 = require("../../utils/utils");
const qualityAssuranceConstants_1 = __importStar(require("./qualityAssuranceConstants"));
exports.getCallDetailRecords = toolkit_1.createAsyncThunk('quality_assurance/call_detail_records', async ({ page, destination, id, review_status, officer_first_name, officer_last_name, external_id, billsec, calldate, disposition, file_availability, call_type, src, officer_id, }, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        const queryParams = utils_1.encodeUrlParams({
            ...(page && { page: String(page) }),
            ...(destination && { destination }),
            ...(id && { id }),
            ...(src && { src }),
            ...(officer_id && { officer_id }),
            ...((review_status === 0 || review_status) && { review_status: String(review_status) }),
            ...((call_type === 0 || call_type) && { call_type: String(call_type) }),
            ...(file_availability && { file_availability: String(file_availability) }),
            ...(officer_first_name && { officer__first_name__icontains: officer_first_name }),
            ...(officer_last_name && { officer__last_name__icontains: officer_last_name }),
            ...(external_id && { schedule__external_id: external_id }),
            ...(billsec && { billsec }),
            ...(disposition && { disposition__iexact: String(disposition) }),
            ...(calldate && { calldate__date: calldate }),
        });
        const urlPath = `${qualityAssuranceConstants_1.default}?${queryParams}`;
        return await network_1.get(urlPath, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        return network_1.handleDefaultError(err, rejectWithValue);
    }
});
exports.auditCallDetailRecord = toolkit_1.createAsyncThunk('quality_assurance/audit/create', async (payload, { dispatch, getState, rejectWithValue }) => {
    try {
        const url = qualityAssuranceConstants_1.CALL_DETAIL_RECORD_AUDIT_URL.replace(constants_1.CALL_DETAIL_RECORD_ID, payload.call_detail_record);
        const { auth } = getState();
        const body = { ...payload };
        const response = await network_1.fetchBackend(url, payload.http_verb || constants_1.HTTP.POST, auth.token, body)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
        // Update review status of the call record if request was successful
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        dispatch(qualityAssuranceSlice.actions.updateCallDetailRecordReviewState(payload.review_status));
        return response;
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.getCallDetailRecordAudit = toolkit_1.createAsyncThunk('quality_assurance/audit/get', async ({ call_detail_record }, { getState, rejectWithValue }) => {
    try {
        const url = qualityAssuranceConstants_1.CALL_DETAIL_RECORD_AUDIT_URL.replace(constants_1.CALL_DETAIL_RECORD_ID, call_detail_record);
        const { auth } = getState();
        return await network_1.get(url, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
const initialState = {
    callRecords: [],
    loadingStatus: typesConstants_1.PENDING_STATUS,
    currentPage: null,
    nextPage: null,
    previousPage: null,
    totalPages: null,
    totalEntries: null,
    pageSize: 10,
    selectedCallRecordIndex: null,
    selectedCallRecordAudit: null,
};
const qualityAssuranceSlice = toolkit_1.createSlice({
    name: 'qualityAssurance',
    initialState,
    reducers: {
        resetCallRecordState() {
            return initialState;
        },
        resetAuditState(state) {
            return { ...state, selectedCallRecordAudit: null, errorMessage: '' };
        },
        setSelectedCallRecordIndex(state, action) {
            return { ...state, selectedCallRecordIndex: action.payload };
        },
        updateCallDetailRecordReviewState(state, action) {
            const index = state.selectedCallRecordIndex;
            const updatedCallRecord = { ...state.callRecords[index], review_status: action.payload };
            const updatedCallRecords = [
                ...state.callRecords.slice(0, index),
                updatedCallRecord,
                ...state.callRecords.slice(index + 1),
            ];
            return { ...state, callRecords: updatedCallRecords };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exports.getCallDetailRecords.pending, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.getCallDetailRecords.fulfilled, (state, { payload }) => ({
            ...state,
            loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
            callRecords: payload.results,
            currentPage: payload.current,
            nextPage: payload.next,
            previousPage: payload.previous,
            totalPages: payload.total_pages,
            totalEntries: payload.total_entries,
            pageSize: payload.page_size,
        }))
            .addCase(exports.getCallDetailRecords.rejected, (state, { payload }) => {
            const newState = { ...state };
            if (payload.message === network_1.message404) {
                newState.loadingStatus = typesConstants_1.FAILED_STATUS;
                newState.errorMessage = constants_1.NOT_FOUND;
            }
            else {
                newState.loadingStatus = typesConstants_1.FAILED_STATUS;
                newState.errorMessage = utils_1.convertObjToPlainString(payload.errorObject || {});
            }
            return newState;
        })
            .addCase(exports.auditCallDetailRecord.pending, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.auditCallDetailRecord.fulfilled, (state, { payload }) => ({
            ...state,
            loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
            selectedCallRecordAudit: payload,
        }))
            .addCase(exports.auditCallDetailRecord.rejected, (state, { payload }) => {
            const newState = { ...state, selectedCallRecordAudit: null };
            if (payload.message === network_1.message404) {
                newState.loadingStatus = typesConstants_1.FAILED_STATUS;
                newState.errorMessage = constants_1.NOT_FOUND;
            }
            else {
                newState.loadingStatus = typesConstants_1.FAILED_STATUS;
                newState.errorMessage = utils_1.convertObjToPlainString(payload.errorObject || {});
            }
            return newState;
        })
            .addCase(exports.getCallDetailRecordAudit.pending, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.getCallDetailRecordAudit.fulfilled, (state, { payload }) => ({
            ...state,
            loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
            selectedCallRecordAudit: payload,
        }))
            .addCase(exports.getCallDetailRecordAudit.rejected, (state, { payload }) => {
            const newState = { ...state, selectedCallRecordAudit: null };
            if (payload.message === network_1.message404) {
                newState.loadingStatus = typesConstants_1.FAILED_STATUS;
                newState.errorMessage = constants_1.NOT_FOUND;
            }
            else {
                newState.loadingStatus = typesConstants_1.FAILED_STATUS;
                newState.errorMessage = utils_1.convertObjToPlainString(payload.errorObject || {});
            }
            return newState;
        });
    },
});
_a = qualityAssuranceSlice.actions, exports.resetCallRecordState = _a.resetCallRecordState, exports.resetAuditState = _a.resetAuditState, exports.setSelectedCallRecordIndex = _a.setSelectedCallRecordIndex;
exports.default = qualityAssuranceSlice.reducer;
