"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trackScreenViewUtil = exports.logEventUtil = void 0;
const logEventUtil = (name, extraProperties = null, mixpanel) => {
    if (extraProperties) {
        mixpanel.track(name, extraProperties);
    }
    else {
        mixpanel.track(name);
    }
};
exports.logEventUtil = logEventUtil;
const trackScreenViewUtil = (previousRouteName, currentRouteName, mixpanel) => {
    if (previousRouteName) {
        mixpanel.track(`exit_${previousRouteName.toLowerCase()}`);
    }
    mixpanel.track(`enter_${currentRouteName.toLowerCase()}`);
};
exports.trackScreenViewUtil = trackScreenViewUtil;
