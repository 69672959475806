"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MIXPANEL_TOKEN = exports.SENTRY_DSN = exports.LINKING_PREFIXES = exports.SOCKET_URL = exports.BACKEND_URL = exports.PRODUCTION = void 0;
exports.PRODUCTION = process.env.NODE_ENV === 'production';
exports.BACKEND_URL = exports.PRODUCTION ? 'https://callcenter-api.lendigo.ng' : 'http://localhost:8000';
exports.SOCKET_URL = exports.PRODUCTION ? 'wss://callcenter-api.lendigo.ng' : 'ws://localhost:8000';
exports.LINKING_PREFIXES = exports.PRODUCTION
    ? ['https://callcenter.lendigo.ng']
    : ['http://localhost:8080', 'localhost:8080'];
exports.SENTRY_DSN = 'http://27225f5fc46041a9864fbc53eed31d43@sentry.int.seedstars.com:9000/7';
exports.MIXPANEL_TOKEN = exports.PRODUCTION ? '263f23ad64ff9ead2dfb312d206bd435' : '030449880aebfe068368adc2aa4084fc';
