"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetPreviousSchedulesState = exports.getPreviousSchedules = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_native_1 = require("react-native");
const constants_1 = require("../../constants/constants");
const typesConstants_1 = require("../../constants/typesConstants");
const network_1 = require("../../utils/network");
const utils_1 = require("../../utils/utils");
const schedulesConstants_1 = require("./schedulesConstants");
exports.getPreviousSchedules = toolkit_1.createAsyncThunk('schedule/list', async (page, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        const today = new Date().toISOString().split('T')[0];
        const previousSchedulePathParams = {
            status: String(schedulesConstants_1.SCHEDULE_STATUS_COMPLETED),
            datetime_created__date: today,
            credit_officer: auth.user.id,
            ...(page && { page: String(page) }),
        };
        const queryParams = utils_1.encodeUrlParams(previousSchedulePathParams);
        const urlPath = `${schedulesConstants_1.OFFICER_SCHEDULES_API_PATH}?${queryParams}`;
        return await network_1.get(urlPath, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
const initialState = {
    schedules: [],
    loadingStatus: typesConstants_1.PENDING_STATUS,
    currentPage: null,
    nextPage: null,
    previousPage: null,
    totalPages: null,
    totalEntries: null,
    pageSize: 10,
};
const previousSchedulesSlice = toolkit_1.createSlice({
    name: 'previousSchedules',
    initialState,
    reducers: {
        resetPreviousSchedulesState() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exports.getPreviousSchedules.pending, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.getPreviousSchedules.fulfilled, (state, { payload }) => ({
            ...state,
            loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
            schedules: payload.results,
            currentPage: payload.current,
            nextPage: payload.next,
            previousPage: payload.previous,
            totalPages: payload.total_pages,
            totalEntries: payload.total_entries,
            pageSize: payload.page_size,
        }))
            .addCase(exports.getPreviousSchedules.rejected, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.FAILED_STATUS,
        }));
    },
});
exports.resetPreviousSchedulesState = previousSchedulesSlice.actions.resetPreviousSchedulesState;
exports.default = previousSchedulesSlice.reducer;
