"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleQueueParams = exports.handleQueueMember = void 0;
const store_1 = require("../../store");
const eventSlice_1 = require("./eventSlice");
const handleQueueMember = (payload) => {
    const officerVoipUserId = payload.MemberName
        ? payload.MemberName.replace('PJSIP/', '')
        : payload.Name.replace('PJSIP/', '');
    store_1.store.dispatch(eventSlice_1.setQueueMember({ ...payload, VoipUserID: officerVoipUserId }));
};
exports.handleQueueMember = handleQueueMember;
const handleQueueParams = (payload) => {
    store_1.store.dispatch(eventSlice_1.setQueueParams(payload));
};
exports.handleQueueParams = handleQueueParams;
