"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.showBrowserNotification = exports.requestPermissionHandler = exports.notificationPermissionNotGranted = exports.serviceWorkerIsSupported = exports.notificationIsSupported = void 0;
const typesConstants_1 = require("../../constants/typesConstants");
exports.notificationIsSupported = 'Notification' in window;
exports.serviceWorkerIsSupported = !!navigator.serviceWorker;
exports.notificationPermissionNotGranted = Notification.permission !== typesConstants_1.BROWSER_NOTIFICATION_GRANTED;
const requestPermissionHandler = (setErrorMessage, setRequestPermission) => {
    // Some browser do not support requesting for notification programatically (e.g. firefox). Request should come from user
    // Interaction e.g clicking a button.
    Notification.requestPermission().then((permission) => {
        // Hide button if user accepts notification.
        if (permission === typesConstants_1.BROWSER_NOTIFICATION_GRANTED) {
            setRequestPermission(false);
            return;
        }
        // Alert the user that permission is required for notification.
        setErrorMessage('Notification permission required. You will not be able to get important notifications. (Enable from permission settings.)');
    });
};
exports.requestPermissionHandler = requestPermissionHandler;
const showBrowserNotification = (messageData, setErrorMessage, setNotificationInState) => {
    const messageOptions = {
        body: messageData.body,
        icon: '/favicon.ico',
        vibrate: [200, 100, 200, 100, 200, 100, 200],
        tag: messageData.tag,
        requireInteraction: false,
    };
    // Ensure service worker is active.
    if (exports.serviceWorkerIsSupported) {
        navigator.serviceWorker.ready.then((registration) => {
            registration.showNotification(messageData.title, messageOptions);
        });
        // Use default notification.
    }
    else {
        const notification = new Notification(messageData.title, messageOptions);
        notification.addEventListener('error', () => {
            setErrorMessage('You blocked this notification');
        });
        if (setNotificationInState) {
            notification.addEventListener('close', () => {
                setNotificationInState(null);
            });
            setNotificationInState(notification);
        }
    }
};
exports.showBrowserNotification = showBrowserNotification;
