"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetNotificationState = exports.deleteNotification = exports.updateNotification = exports.getNotifications = void 0;
const react_native_1 = require("react-native");
const toolkit_1 = require("@reduxjs/toolkit");
const typesConstants_1 = require("../../constants/typesConstants");
const utils_1 = require("../../utils/utils");
const network_1 = require("../../utils/network");
const constants_1 = require("../../constants/constants");
const notificationConstants_1 = require("./notificationConstants");
const initialState = {
    notifications: [],
    loadingStatus: typesConstants_1.PENDING_STATUS,
    currentPage: null,
    nextPage: null,
    previousPage: null,
    totalPages: null,
    totalEntries: null,
    pageSize: 10,
};
exports.getNotifications = toolkit_1.createAsyncThunk('notifications', async ({ page, read, content_type, send_at }, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        const queryParams = utils_1.encodeUrlParams({
            ...(page && { page: String(page) }),
            ...(read && { read: String(read) }),
            ...(content_type && { content_type__model: String(content_type) }),
            ...(send_at && { send_at__date: send_at }),
        });
        const urlPath = `${notificationConstants_1.NOTIFICATIONS_CREATE_OR_LIST_URL}?${queryParams}`;
        return await network_1.fetchBackend(urlPath, constants_1.HTTP.GET, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        return network_1.handleDefaultError(err, rejectWithValue);
    }
});
exports.updateNotification = toolkit_1.createAsyncThunk('notifications/update', async (payload, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        const body = { ...payload };
        const url = notificationConstants_1.NOTIFICATIONS_DETAIL_OR_UPDATE_URL.replace(constants_1.NOTIFICATION_ID_TAG, payload.notification_id);
        const response = await network_1.fetchBackend(url, constants_1.HTTP.PATCH, auth.token, body)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
        return response;
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.deleteNotification = toolkit_1.createAsyncThunk('notifications/delete', async (payload, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        const url = notificationConstants_1.NOTIFICATIONS_DETAIL_OR_UPDATE_URL.replace(constants_1.NOTIFICATION_ID_TAG, payload.notification_id);
        const response = await network_1.fetchBackend(url, constants_1.HTTP.DELETE, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
        return response;
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
const notificationSlice = toolkit_1.createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        resetNotificationState() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exports.getNotifications.pending, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.getNotifications.fulfilled, (state, { payload }) => ({
            ...state,
            loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
            notifications: payload.results,
            currentPage: payload.current,
            nextPage: payload.next,
            previousPage: payload.previous,
            totalPages: payload.total_pages,
            totalEntries: payload.total_entries,
            pageSize: payload.page_size,
        }))
            .addCase(exports.getNotifications.rejected, (state, { payload }) => {
            const errorMessage = payload.message === network_1.message404 ? constants_1.NOT_FOUND : utils_1.convertObjToPlainString(payload.errorObject || {});
            return {
                ...state,
                loadingStatus: typesConstants_1.FAILED_STATUS,
                errorMessage,
            };
        })
            .addCase(exports.updateNotification.pending, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.updateNotification.fulfilled, (state, { payload }) => {
            // Find the index of the notification to be updated.
            const notificationIndex = state.notifications.findIndex((notification) => notification.id === payload.id);
            if (notificationIndex !== -1) {
                const updatedNotifications = [...state.notifications];
                updatedNotifications[notificationIndex] = payload;
                return {
                    ...state,
                    loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
                    notifications: updatedNotifications,
                };
            }
            return state; // Return the current state if the notification is not found
        })
            .addCase(exports.updateNotification.rejected, (state, { payload }) => {
            const errorMessage = payload.message === network_1.message404 ? constants_1.NOT_FOUND : utils_1.convertObjToPlainString(payload.errorObject || {});
            return {
                ...state,
                loadingStatus: typesConstants_1.FAILED_STATUS,
                errorMessage,
            };
        })
            .addCase(exports.deleteNotification.pending, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.deleteNotification.fulfilled, (state, action) => {
            const deleteNotificationId = action.meta.arg.notification_id;
            // Filter out the deleted notification from notifications
            const updatedNotifications = state.notifications.filter((notification) => notification.id !== deleteNotificationId);
            return {
                ...state,
                loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
                notifications: updatedNotifications,
            };
        })
            .addCase(exports.deleteNotification.rejected, (state, { payload }) => {
            const errorMessage = payload.message === network_1.message404 ? constants_1.NOT_FOUND : utils_1.convertObjToPlainString(payload.errorObject || {});
            return {
                ...state,
                loadingStatus: typesConstants_1.FAILED_STATUS,
                errorMessage,
            };
        });
    },
});
exports.resetNotificationState = notificationSlice.actions.resetNotificationState;
exports.default = notificationSlice.reducer;
