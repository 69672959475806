"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const usePaginator = (currentPage, totalPages, numOfSchedulesInPage, totalEntries) => {
    let pageStart = 0;
    let pageEnd = 0;
    if (currentPage) {
        // If we're at the last page
        if (currentPage >= totalPages) {
            pageStart = (currentPage - 1) * numOfSchedulesInPage + 1;
            pageEnd = totalEntries;
        }
        // If we're at the first page
        else if (currentPage && currentPage === 1) {
            pageStart = 1;
            pageEnd = numOfSchedulesInPage;
        }
        else {
            pageStart = (currentPage - 1) * numOfSchedulesInPage + 1;
            pageEnd = currentPage * numOfSchedulesInPage;
        }
    }
    return { pageStart, pageEnd };
};
exports.default = usePaginator;
