"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SCHEDULE_STATUS_RECYCLE = exports.SCHEDULE_STATUS_RESCHEDULED = exports.SCHEDULE_STATUS_CANCELED = exports.SCHEDULE_STATUS_COMPLETED = exports.SCHEDULE_STATUS_STARTED = exports.SCHEDULE_STATUS_NOT_STARTED = exports.SCHEDULE_EXTERNAL_DATA_ID_API_PATH = exports.OFFICER_SCHEDULES_API_PATH = exports.NEXT_SCHEDULE_API_PATH = exports.RECYCLE_SCHEDULE_API_PATH = exports.RESCHEDULE_SCHEDULE_API_PATH = exports.COMPLETE_SCHEDULE_API_PATH = exports.START_COMPLETE_AUTOMATED_SCHEDULE_API_PATH = exports.START_SCHEDULE_API_PATH = exports.SCHEDULE_ID_API_PATH = void 0;
const constants_1 = require("../../constants/constants");
exports.SCHEDULE_ID_API_PATH = `schedules/${constants_1.SCHEDULE_ID_TEMPLATE_TAG}/`;
exports.START_SCHEDULE_API_PATH = `schedules/${constants_1.SCHEDULE_ID_TEMPLATE_TAG}/start/`;
exports.START_COMPLETE_AUTOMATED_SCHEDULE_API_PATH = `schedules/${constants_1.SCHEDULE_ID_TEMPLATE_TAG}/automated/process/`;
exports.COMPLETE_SCHEDULE_API_PATH = `schedules/${constants_1.SCHEDULE_ID_TEMPLATE_TAG}/complete/`;
exports.RESCHEDULE_SCHEDULE_API_PATH = `schedules/${constants_1.SCHEDULE_ID_TEMPLATE_TAG}/reschedule/`;
exports.RECYCLE_SCHEDULE_API_PATH = `schedules/${constants_1.SCHEDULE_ID_TEMPLATE_TAG}/recycle/`;
exports.NEXT_SCHEDULE_API_PATH = 'schedules/next/';
exports.OFFICER_SCHEDULES_API_PATH = 'schedules/officer-schedules/';
exports.SCHEDULE_EXTERNAL_DATA_ID_API_PATH = `customers/data/${constants_1.EXTERNAL_ID}/`;
exports.SCHEDULE_STATUS_NOT_STARTED = 0;
exports.SCHEDULE_STATUS_STARTED = 1;
exports.SCHEDULE_STATUS_COMPLETED = 2;
exports.SCHEDULE_STATUS_CANCELED = 3;
exports.SCHEDULE_STATUS_RESCHEDULED = 4;
exports.SCHEDULE_STATUS_RECYCLE = 5;
