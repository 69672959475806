"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const styles = react_native_1.StyleSheet.create({
    paddingNav: {
        paddingHorizontal: 2,
        paddingVertical: 0,
    },
});
exports.default = styles;
