"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setScheduleData = exports.toggleRedFlag = exports.toggleEscalateToFieldRecovery = exports.toggleSelectedContactDoNotCall = exports.resetScheduleState = exports.recycleSchedule = exports.rescheduleSchedule = exports.completeSchedule = exports.startSchedule = exports.getScheduleExternalData = exports.getNextSchedule = exports.getSchedule = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_native_1 = require("react-native");
const constants_1 = require("../../constants/constants");
const typesConstants_1 = require("../../constants/typesConstants");
const network_1 = require("../../utils/network");
const utils_1 = require("../../utils/utils");
const schedulesConstants_1 = require("./schedulesConstants");
exports.getSchedule = toolkit_1.createAsyncThunk('schedule/get', async ({ scheduleId }, { getState, rejectWithValue }) => {
    try {
        const url = schedulesConstants_1.SCHEDULE_ID_API_PATH.replace(constants_1.SCHEDULE_ID_TEMPLATE_TAG, scheduleId);
        const { auth } = getState();
        return await network_1.get(url, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.getNextSchedule = toolkit_1.createAsyncThunk('schedule/next/', async (_, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        return await network_1.post(schedulesConstants_1.NEXT_SCHEDULE_API_PATH, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.getScheduleExternalData = toolkit_1.createAsyncThunk('schedule/loan', async ({ externalId }, { getState, rejectWithValue }) => {
    try {
        const url = schedulesConstants_1.SCHEDULE_EXTERNAL_DATA_ID_API_PATH.replace(constants_1.EXTERNAL_ID, externalId);
        const { auth } = getState();
        return await network_1.post(url, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.startSchedule = toolkit_1.createAsyncThunk('schedule/start', async ({ scheduleId, isAutomated }, { getState, rejectWithValue }) => {
    try {
        const url = (isAutomated ? schedulesConstants_1.START_COMPLETE_AUTOMATED_SCHEDULE_API_PATH : schedulesConstants_1.START_SCHEDULE_API_PATH).replace(constants_1.SCHEDULE_ID_TEMPLATE_TAG, scheduleId);
        const { auth } = getState();
        return await network_1.post(url, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.completeSchedule = toolkit_1.createAsyncThunk('schedule/complete', async ({ scheduleId }, { getState, rejectWithValue }) => {
    try {
        const url = schedulesConstants_1.COMPLETE_SCHEDULE_API_PATH.replace(constants_1.SCHEDULE_ID_TEMPLATE_TAG, scheduleId);
        const { auth } = getState();
        return await network_1.post(url, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.rescheduleSchedule = toolkit_1.createAsyncThunk('schedule/reschedule', async ({ scheduleId, rescheduleDateTime }, { getState, rejectWithValue }) => {
    try {
        const url = schedulesConstants_1.RESCHEDULE_SCHEDULE_API_PATH.replace(constants_1.SCHEDULE_ID_TEMPLATE_TAG, scheduleId);
        const { auth } = getState();
        return await network_1.post(url, auth.token, { reschedule_datetime: rescheduleDateTime.toISOString() })
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.recycleSchedule = toolkit_1.createAsyncThunk('schedule/recycle', async ({ scheduleId }, { getState, rejectWithValue }) => {
    try {
        const url = schedulesConstants_1.RECYCLE_SCHEDULE_API_PATH.replace(constants_1.SCHEDULE_ID_TEMPLATE_TAG, scheduleId);
        const { auth } = getState();
        return await network_1.post(url, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
const initialState = {
    status: typesConstants_1.IDLE_STATUS,
    schedule: null,
    loan: null,
    user: null,
    callLaterStatus: {
        status: typesConstants_1.IDLE_STATUS,
        errors: {},
    },
};
const scheduleSlice = toolkit_1.createSlice({
    name: 'schedule',
    initialState,
    reducers: {
        resetScheduleState() {
            return initialState;
        },
        toggleEscalateToFieldRecovery(state) {
            if (state.loan) {
                const oldValue = state.loan.loan_data.escalated_to_field_recovery;
                // escalated_to_field_recovery is a boolean here that's why we don't need to check if it is undefined
                const newLoanData = { ...state.loan.loan_data, escalated_to_field_recovery: !oldValue };
                const newLoan = { ...state.loan, loan_data: newLoanData };
                return { ...state, loan: newLoan };
            }
            return { ...state };
        },
        toggleRedFlag(state) {
            if (state.loan) {
                const oldValue = state.loan.loan_data.red_flag;
                // red_flag is a boolean here that's why we don't need to check if it is undefined
                const newLoanData = { ...state.loan.loan_data, redFlag: !oldValue };
                const newLoan = { ...state.loan, loan_data: newLoanData };
                return { ...state, loan: newLoan };
            }
            return { ...state };
        },
        toggleSelectedContactDoNotCall(state, { payload }) {
            if (state.loan) {
                const { borrowers } = { ...state.loan };
                // need to create a copy of the contacts array to create a copy instead of using the ref
                const borrower = {
                    ...borrowers[0],
                    contacts: state.loan.borrowers[0].contacts.map((contact) => ({
                        ...contact,
                    })),
                };
                if (borrower) {
                    // HAS BORROWER
                    if (!payload.relationship) {
                        // THIS IS THE BORROWER
                        borrower.do_not_call = !borrower.do_not_call;
                        return { ...state, loan: { ...state.loan, borrowers: [borrower] } };
                    }
                    // THIS IS THE BORROWER'S CONTACT
                    const { contacts } = borrower;
                    const contactSelectedIndex = borrower.contacts.findIndex((contact) => contact.first_name === payload.first_name &&
                        contact.last_name === payload.last_name &&
                        contact.phone_number === payload.phone_number);
                    if (contacts && contactSelectedIndex !== -1) {
                        contacts[contactSelectedIndex].do_not_call = !contacts[contactSelectedIndex].do_not_call;
                        return {
                            ...state,
                            loan: {
                                ...state.loan,
                                borrowers: [{ ...borrowers[0], contacts }],
                            },
                        };
                    }
                    return {
                        ...state,
                        status: typesConstants_1.FAILED_STATUS,
                        errorMessage: "Can't find the contact selected",
                    };
                }
            }
            return { ...state };
        },
        setScheduleData(state, { payload }) {
            // We don't wanna update if the current schedule ID matches the new schedule.
            if (state.schedule && state.schedule.id === payload.schedule.id) {
                return state;
            }
            const newState = { ...state, schedule: payload.schedule };
            if (newState.schedule.external_id_type === typesConstants_1.EXTERNAL_ID_TYPE_USER) {
                newState.user = payload.data;
            }
            else {
                newState.loan = payload.data;
            }
            return newState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exports.getSchedule.pending, (state) => ({
            ...state,
            status: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.getSchedule.rejected, (state, { payload }) => {
            const newState = { ...state };
            if (payload.message === network_1.message404) {
                newState.status = typesConstants_1.FAILED_STATUS;
                newState.errorMessage = constants_1.INVALID_SCHEDULES_MESSAGE;
            }
            else {
                newState.status = typesConstants_1.FAILED_STATUS;
                newState.errorMessage = payload.message;
            }
            return newState;
        })
            .addCase(exports.getSchedule.fulfilled, (state, { payload }) => ({
            ...state,
            status: typesConstants_1.PENDING_STATUS,
            schedule: payload,
        }))
            .addCase(exports.startSchedule.pending, (state) => ({
            ...state,
            status: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.startSchedule.rejected, (state, { payload }) => {
            const newState = { ...state };
            newState.status = typesConstants_1.FAILED_STATUS;
            newState.errorMessage = payload.message;
            return newState;
        })
            .addCase(exports.startSchedule.fulfilled, (state, { payload }) => ({
            ...state,
            status: typesConstants_1.SUCCEEDED_STATUS,
            schedule: { ...state.schedule, ...payload }, // we merge here to prevent loss of the schedules_not_started field
        }))
            .addCase(exports.completeSchedule.pending, (state) => ({
            ...state,
            status: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.completeSchedule.rejected, (state, { payload }) => {
            const newState = { ...state };
            newState.status = typesConstants_1.FAILED_STATUS;
            newState.errorMessage = payload.message;
            return newState;
        })
            .addCase(exports.completeSchedule.fulfilled, (state, { payload }) => ({
            ...state,
            status: typesConstants_1.SUCCEEDED_STATUS,
            schedule: { ...state.schedule, ...payload }, // we merge here to prevent loss of the schedules_not_started field
        }))
            .addCase(exports.getNextSchedule.pending, (state) => ({
            ...state,
            status: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.getNextSchedule.rejected, (state, { payload }) => {
            const newState = { ...state };
            if (payload.message === network_1.message404) {
                newState.status = typesConstants_1.FAILED_STATUS;
                newState.errorMessage = constants_1.NO_SCHEDULES_MESSAGE;
            }
            else {
                newState.status = typesConstants_1.FAILED_STATUS;
                newState.errorMessage = payload.message;
            }
            return newState;
        })
            .addCase(exports.getNextSchedule.fulfilled, (state, { payload }) => ({
            ...state,
            status: typesConstants_1.PENDING_STATUS,
            schedule: payload,
        }))
            .addCase(exports.getScheduleExternalData.pending, (state) => ({
            ...state,
            status: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.getScheduleExternalData.rejected, (state, { payload }) => {
            const newState = { ...state };
            newState.status = typesConstants_1.FAILED_STATUS;
            if (payload.message === network_1.message502) {
                newState.errorMessage = constants_1.ERROR_RETRIEVING_LOAN_MESSAGE;
            }
            else if (payload.code === network_1.statusCode400BadRequest) {
                newState.errorMessage = utils_1.getError('non_field_errors', payload.errorObject);
            }
            else {
                newState.errorMessage = payload.message;
            }
            return newState;
        })
            .addCase(exports.getScheduleExternalData.fulfilled, (state, { payload }) => {
            const newState = { ...state };
            newState.status = typesConstants_1.SUCCEEDED_STATUS;
            if (newState.schedule.external_id_type === typesConstants_1.EXTERNAL_ID_TYPE_USER) {
                newState.user = payload;
            }
            else {
                newState.loan = payload;
            }
            return newState;
        })
            .addCase(exports.rescheduleSchedule.pending, (state) => ({
            ...state,
            callLaterStatus: {
                status: typesConstants_1.PENDING_STATUS,
            },
        }))
            .addCase(exports.rescheduleSchedule.rejected, (state, { payload }) => {
            return {
                ...state,
                callLaterStatus: {
                    errors: payload.errorObject,
                    status: typesConstants_1.FAILED_STATUS,
                },
            };
        })
            .addCase(exports.rescheduleSchedule.fulfilled, (state) => ({
            ...state,
            callLaterStatus: {
                status: typesConstants_1.SUCCEEDED_STATUS,
            },
        }))
            .addCase(exports.recycleSchedule.pending, (state) => ({
            ...state,
            callLaterStatus: {
                status: typesConstants_1.PENDING_STATUS,
            },
        }))
            .addCase(exports.recycleSchedule.rejected, (state, { payload }) => {
            return {
                ...state,
                callLaterStatus: {
                    errors: payload.errorObject,
                    status: typesConstants_1.FAILED_STATUS,
                },
            };
        })
            .addCase(exports.recycleSchedule.fulfilled, (state) => ({
            ...state,
            callLaterStatus: {
                status: typesConstants_1.SUCCEEDED_STATUS,
            },
        }));
    },
});
_a = scheduleSlice.actions, exports.resetScheduleState = _a.resetScheduleState, exports.toggleSelectedContactDoNotCall = _a.toggleSelectedContactDoNotCall, exports.toggleEscalateToFieldRecovery = _a.toggleEscalateToFieldRecovery, exports.toggleRedFlag = _a.toggleRedFlag, exports.setScheduleData = _a.setScheduleData;
exports.default = scheduleSlice.reducer;
