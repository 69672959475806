"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HTTP = exports.POSITION = exports.ALPHA_INVALID_ERROR = exports.ONLY_ALPHA_REGEX = exports.NUMBER_TOO_LONG_ERROR = exports.NUMBER_TOO_SHORT_ERROR = exports.ONLY_DIGIT_REGEX = exports.NUMBER_INVALID_ERROR = exports.QUICKCHECK = exports.QUICKCHECK_CRM_URL = exports.OFFICER_STATUS_PENDING = exports.OFFICER_STATUS_UNPAUSED = exports.OFFICER_STATUS_PAUSED = exports.WEB_TAG = exports.PENDING_RESPONSE_CONSTANT = exports.REJECTED_RESPONSE_CONSTANT = exports.FULFILLED_RESPONSE_CONSTANT = exports.NOTIFICATION_ID_TAG = exports.COMMENT_ID = exports.TICKET_ID = exports.CALL_DETAIL_RECORD_ID = exports.EXTERNAL_ID = exports.CAMPAIGN_ID_TEMPLATE_TAG = exports.SCHEDULE_ID_TEMPLATE_TAG = exports.DESKTOP_BREAKPOINT_2 = exports.DESKTOP_BREAKPOINT_1 = exports.DESKTOP_BREAKPOINT = exports.SAVE_AND_CLOSE = exports.ERROR_RETRIEVING_LOAN_MESSAGE = exports.INVALID_SCHEDULES_MESSAGE = exports.NO_SCHEDULES_MESSAGE = exports.NOT_FOUND = exports.DEFAULT_NETWORK_ERROR_MESSAGE = exports.API_URL = void 0;
exports.API_URL = '/api/';
exports.DEFAULT_NETWORK_ERROR_MESSAGE = 'Network request failed'; // this is default error message from framework
exports.NOT_FOUND = 'Object Not Found.';
exports.NO_SCHEDULES_MESSAGE = 'All good! No more schedules at the moment.';
exports.INVALID_SCHEDULES_MESSAGE = 'No schedule with this ID.';
exports.ERROR_RETRIEVING_LOAN_MESSAGE = 'Error retrieving loan for this schedule.';
exports.SAVE_AND_CLOSE = 'Save & Close';
exports.DESKTOP_BREAKPOINT = 1220;
exports.DESKTOP_BREAKPOINT_1 = 1800;
exports.DESKTOP_BREAKPOINT_2 = 1500;
exports.SCHEDULE_ID_TEMPLATE_TAG = '<scheduleId>';
exports.CAMPAIGN_ID_TEMPLATE_TAG = '<campaignId>';
exports.EXTERNAL_ID = '<externalId>';
exports.CALL_DETAIL_RECORD_ID = '<callDetailRecordId>';
exports.TICKET_ID = '<ticketId>';
exports.COMMENT_ID = '<commentId>';
exports.NOTIFICATION_ID_TAG = '<notificationId>';
exports.FULFILLED_RESPONSE_CONSTANT = 'fulfilled';
exports.REJECTED_RESPONSE_CONSTANT = 'rejected';
exports.PENDING_RESPONSE_CONSTANT = 'pending';
exports.WEB_TAG = 'web';
exports.OFFICER_STATUS_PAUSED = 0;
exports.OFFICER_STATUS_UNPAUSED = 1;
exports.OFFICER_STATUS_PENDING = -1;
exports.QUICKCHECK_CRM_URL = 'https://loanapp.quickcheck.ng/admin/loan';
exports.QUICKCHECK = 'Quickcheck';
exports.NUMBER_INVALID_ERROR = 'The number is not formatted properly. Only digits are allowed.';
exports.ONLY_DIGIT_REGEX = /^\d*$/;
exports.NUMBER_TOO_SHORT_ERROR = 'The phone number must be at least 9 digits.';
exports.NUMBER_TOO_LONG_ERROR = 'The phone number must not be longer than 13 digits.';
exports.ONLY_ALPHA_REGEX = /^[a-zA-Z()]+$/;
exports.ALPHA_INVALID_ERROR = 'Only alphabets are allowed.';
exports.POSITION = {
    LEFT: 'left',
    RIGHT: 'right',
};
exports.HTTP = {
    GET: 'GET',
    POST: 'POST',
    PATCH: 'PATCH',
    DELETE: 'DELETE',
};
