"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.evaluationFields = exports.evaulationFieldsKAM = exports.evaulationFieldsCS = exports.evaulationFieldsCC = exports.DEMOGRAPHIC_FIELDS_KEYS = exports.TAG_CHOICES = exports.OTHER_TAG_CHOICE = exports.DELAYED_PAYMENT_REASON_KAM_CHOICES = exports.DELAYED_PAYMENT_REASON_CC_CHOICES = exports.FATAL = void 0;
const qualityAssuranceConstants_1 = require("../../features/qualityAssurance/qualityAssuranceConstants");
const filterCallRecordConstants_1 = require("../filterCallRecord/filterCallRecordConstants");
const OPPORTUNITY_DID_NOT_PRESENT_ITSELF = 'Opportunity did not present itself';
exports.FATAL = '-2';
// General;
const GREETINGS_CHOICES = [
    { value: -1, label: OPPORTUNITY_DID_NOT_PRESENT_ITSELF },
    { value: 0, label: "Did not use call script & customer's name/Sir/Ma" },
    { value: 5, label: "Use of call script & customer's name" },
];
const INTERRUPTION_CHOICES = [
    { value: -2, label: 'Interrupted thrice and more' },
    { value: 0, label: 'Interrupted twice' },
    { value: 2, label: 'Interruption once' },
    { value: 5, label: 'No interruption' },
];
const ARTICULATION_CHOICES = [
    { value: 0, label: 'Inartculate/Several grammatical errors/use of negative language and did not take ownership' },
    { value: 3, label: 'Inartculate/Few grammatical errors//Positive language and sometimes did not take ownership' },
    { value: 5, label: 'Articulate/No grammatical error/Positive language and took ownership' },
];
const DEAD_AIR_CHOICES = [{ value: 0, label: 'Dead air' }, { value: 3, label: 'No dead air' }];
const ATTENTIVENESS_CHOICES = [
    { value: -2, label: 'Distraction' },
    { value: 0, label: 'Did not listen attentively/Distractions on the call' },
    { value: 5, label: 'Listened attentively' },
];
const POLITE_CHOICES = [
    { value: -2, label: 'Rude' },
    { value: 0, label: 'Impolite/Disrespectful' },
    { value: 4, label: 'Polite/Respectful' },
];
const EMPATHY_CHOICES = [
    { value: -2, label: 'Delibrately failed to show empathy' },
    { value: -1, label: OPPORTUNITY_DID_NOT_PRESENT_ITSELF },
    { value: 0, label: 'Did not show empathy' },
    { value: 8, label: 'Showed empathy' },
];
const RAPPORT_CHOICES = [
    { value: -1, label: OPPORTUNITY_DID_NOT_PRESENT_ITSELF },
    { value: 0, label: 'Did not established rapport/show enthusiasm' },
    { value: 8, label: 'Established rapport/Showed enthusiasm' },
];
const USER_DETAILS_CHOICES = [
    { value: -2, label: 'Wrong information' },
    { value: -1, label: OPPORTUNITY_DID_NOT_PRESENT_ITSELF },
    { value: 0, label: 'Did not inform/confirm any details' },
    { value: 3, label: 'Informed/Confirmed some details' },
    { value: 6, label: 'Informed/Confirmed all details' },
];
const CALL_TAG_CHOICES = [
    { value: -1, label: OPPORTUNITY_DID_NOT_PRESENT_ITSELF },
    { value: 0, label: 'Did not tag appropriately' },
    { value: 5, label: 'Tagged appropriately' },
];
const CALL_SUMMARY_CHOICES = [
    { value: -1, label: OPPORTUNITY_DID_NOT_PRESENT_ITSELF },
    { value: 0, label: 'No note' },
    { value: 3, label: 'No detailed Summary' },
    { value: 5, label: 'Detailed Summary' },
];
const TONE_AND_PEACE_CHOICES = [
    { value: 0, label: 'Inappropriate tone/pace' },
    { value: 1, label: 'Tone/pace fluctuation' },
    { value: 2, label: 'Appropriate tone/pace' },
];
// Collection only
const CALL_HISTORY_CC_CHOICES = [
    { value: -1, label: OPPORTUNITY_DID_NOT_PRESENT_ITSELF },
    { value: 0, label: 'Did not refer to call history' },
    { value: 3, label: 'Referred to call history' },
];
const PROMISE_TO_PAY_CHOICES = [
    { value: -1, label: `${OPPORTUNITY_DID_NOT_PRESENT_ITSELF}/Customer refused to give one` },
    { value: 0, label: 'PTP not negotiated' },
    { value: 20, label: 'PTP Negotiated' },
];
const PAYMENT_DELAY_CHOICES = [
    { value: -1, label: OPPORTUNITY_DID_NOT_PRESENT_ITSELF },
    { value: 0, label: 'Did not ask for the reason for delayed payment' },
    { value: 5, label: 'Asked for the reason for delayed payment' },
];
const LOAN_DETAILS_CHOICES = [
    { value: -2, label: 'Wrong information' },
    { value: -1, label: OPPORTUNITY_DID_NOT_PRESENT_ITSELF },
    { value: 0, label: 'Did not inform/confirm any details including T&Cs' },
    { value: 3, label: 'Informed/Confirmed some details including T&Cs' },
    { value: 6, label: 'Informed/Confirmed all details including T&Cs' },
];
exports.DELAYED_PAYMENT_REASON_CC_CHOICES = [
    { value: 0, label: 'Delayed Salary' },
    { value: 1, label: 'Childbirth' },
    { value: 2, label: 'Change of Job' },
    { value: 3, label: 'Ill - Health' },
    { value: 4, label: 'Incorrect profiling' },
    { value: 5, label: 'Issues with Employer' },
    { value: 6, label: 'Previous Experience' },
    { value: 7, label: 'Reduction in Income' },
    { value: 8, label: 'Dispute' },
    { value: 9, label: 'Job loss' },
    { value: 10, label: 'No capacity to pay' },
    { value: 11, label: 'No intention to pay' },
    { value: 12, label: 'Service issues' },
    { value: 13, label: 'Language Barrier' },
    { value: 14, label: 'Fraud case' },
    { value: 15, label: 'Deceased' },
    { value: 16, label: 'Others' },
    { value: 17, label: 'Expecting money' },
];
// Support only
const ISSUE_RESOLVED_CHOICES = [
    { value: -2, label: 'Wrong resolution/Information' },
    { value: 0, label: "Did not resolve customer's issue/answer all questions" },
    { value: 5, label: 'Some issues resolved/questions answered' },
    { value: 11, label: 'Issue resolved/All questions answered' },
];
const ISSUE_ESCALATED_CHOICES = [
    { value: -1, label: OPPORTUNITY_DID_NOT_PRESENT_ITSELF },
    { value: 0, label: 'Issue not escalated' },
    { value: 6, label: 'Issue escalated but timeline not mentioned' },
    { value: 11, label: 'Issue escalated and timeline mentioned' },
];
const ARTICULATION_CS_CHOICES = [
    { value: 0, label: 'Inartculate/Several grammatical errors/use of negative language and did not take ownership' },
    { value: 2, label: 'Inartculate/Few grammatical errors//Positive language and sometimes did not take ownership' },
    { value: 4, label: 'Articulate/No grammatical error/Positive language and took ownership' },
];
const ATTENTIVENESS_CS_CHOICES = [
    { value: -2, label: 'Distraction' },
    { value: 0, label: 'Did not listen attentively/Distractions on the call' },
    { value: 4, label: 'Listened attentively' },
];
const HOLD_PROCEDURE_CHOICES = [
    { value: -1, label: OPPORTUNITY_DID_NOT_PRESENT_ITSELF },
    { value: 0, label: 'Did not follow the hold procedure' },
    { value: 2, label: 'Followed the hold procedure' },
];
const LOAN_DETAILS_CS_CHOICES = [
    { value: -2, label: 'Wrong information' },
    { value: -1, label: OPPORTUNITY_DID_NOT_PRESENT_ITSELF },
    { value: 0, label: 'Did not inform/confirm any details including T&Cs' },
    { value: 6, label: 'Informed/Confirmed some details including T&Cs' },
    { value: 12, label: 'Informed/Confirmed all details including T&Cs' },
];
// Key Account only
const CALL_HISTORY_KAM_CHOICES = [
    { value: -1, label: OPPORTUNITY_DID_NOT_PRESENT_ITSELF },
    { value: 0, label: 'Agent did not record a note' },
    { value: 6, label: 'Agent recorded a note' },
];
const GREETINGS_KAM_CHOICES = [
    { value: -1, label: OPPORTUNITY_DID_NOT_PRESENT_ITSELF },
    { value: 0, label: "Did not use call script & customer's name/Sir/Ma" },
    { value: 6, label: "Use of call script & customer's name" },
];
const NEGOTIATION_CHOCIES = [
    { value: -1, label: OPPORTUNITY_DID_NOT_PRESENT_ITSELF },
    { value: 0, label: 'Loan amount/increment/reasons for default not negotiated' },
    { value: 14, label: 'Loan amount/increment/reasons for default negotiated' },
];
const ISSUE_RESOLVED_KAM_CHOICES = [
    { value: 0, label: "Did not resolve customer's issue/Did not answer any questions" },
    { value: 8, label: "Did not resolve customer's issue/Answered some questions" },
    { value: 16, label: 'Issue resolved/All questions answered' },
];
const ISSUE_ESCALATED_KAM_CHOICES = [
    { value: -1, label: OPPORTUNITY_DID_NOT_PRESENT_ITSELF },
    { value: 0, label: 'No call escalation and no follow up' },
    { value: 8, label: 'Timeline not mentioned' },
    { value: 16, label: 'Issue escalated and timeline mentioned / Follow up' },
];
const TONE_AND_PEACE_KAM_CHOICES = [
    { value: 0, label: 'Inappropriate tone/pace' },
    { value: 2, label: 'Tone/pace fluctuation' },
    { value: 4, label: 'Appropriate tone/pace' },
];
const POLITE_KAM_CHOICES = [
    { value: -2, label: 'Rude' },
    { value: 0, label: 'Impolite/Disrespectful' },
    { value: 6, label: 'Polite/Respectful' },
];
exports.DELAYED_PAYMENT_REASON_KAM_CHOICES = [
    { value: 0, label: 'Loss of Job' },
    { value: 1, label: 'Do not need a loan' },
    { value: 2, label: 'App issues' },
    { value: 3, label: 'High interest' },
    { value: 4, label: 'Higher amount' },
    { value: 5, label: 'Login issues' },
    { value: 6, label: 'Adding card issues' },
    { value: 7, label: 'Stuck on a certain amount' },
    { value: 8, label: 'Pin reset' },
    { value: 9, label: 'Poor customer service' },
    { value: 10, label: 'Positive comment' },
    { value: 11, label: 'Others' },
    { value: 12, label: 'Financial issues' },
    { value: 13, label: 'Health issues' },
    { value: 14, label: 'Bank issues' },
];
exports.OTHER_TAG_CHOICE = 55;
exports.TAG_CHOICES = [
    { value: 1, label: 'Account Suspension' },
    { value: 2, label: 'Active loan extension' },
    { value: 3, label: 'Adding card issues' },
    { value: 4, label: 'Auto Debit Request' },
    { value: 6, label: 'BVN Validation issues QC' },
    { value: 7, label: 'Canceled Loans' },
    { value: 8, label: 'Cant make payment via card' },
    { value: 9, label: 'Cant make payment via Monnify/GT' },
    { value: 10, label: 'Card Linked to other users' },
    { value: 12, label: 'Consumer MFB' },
    { value: 13, label: 'Contact Person' },
    { value: 14, label: 'Converted to 5 Stars' },
    { value: 15, label: 'Credibuilder Complaints' },
    { value: 17, label: 'Disbursement complaint' },
    { value: 18, label: 'Disbursement inquiry' },
    { value: 19, label: 'Double debit via Card' },
    { value: 21, label: 'Dropped Call' },
    { value: 25, label: 'High Interests' },
    { value: 26, label: 'IOS compatible' },
    { value: 27, label: 'Jumia App Issue' },
    { value: 28, label: 'Jumia Loan Inquiry' },
    { value: 29, label: 'Jumia Loan rejection' },
    { value: 30, label: 'Language Barrier' },
    { value: 31, label: 'Late disbursement' },
    { value: 32, label: 'Loan Extension STAGE A' },
    { value: 33, label: 'Loan Extension STAGE B' },
    { value: 34, label: 'Loan Extension STAGE C' },
    { value: 35, label: 'Loan Extension STAGE D' },
    { value: 36, label: 'Loan Extension STAGE E' },
    { value: 38, label: 'Loan extension Undefined STAGE' },
    { value: 39, label: 'Loan increment' },
    { value: 40, label: 'Loan Limit Reduction' },
    { value: 41, label: 'Loan Overpayment' },
    { value: 42, label: 'Loan payment complaint Providus' },
    { value: 44, label: 'Loan payment complaint Monnify' },
    { value: 45, label: 'Loan payment complaint Paystack' },
    { value: 46, label: 'Loan Status Payment Inquiry' },
    { value: 47, label: 'Merged Accounts' },
    { value: 49, label: 'Negative Remanks' },
    { value: 51, label: 'No ATM Card' },
    { value: 52, label: 'Noisy Call' },
    { value: 53, label: 'Not related to QC' },
    { value: 54, label: 'Other means of Login' },
    { value: exports.OTHER_TAG_CHOICE, label: 'Others' },
    { value: 56, label: 'OTP Request' },
    { value: 57, label: 'Part payment request' },
    { value: 58, label: 'Phone Finance' },
    { value: 59, label: 'Phone Not Compatible' },
    { value: 60, label: 'Pin Reset Issue' },
    { value: 61, label: 'Postive Remarks' },
    { value: 62, label: 'Promised to pay' },
    { value: 63, label: 'QC App issues/login issues' },
    { value: 64, label: 'QC App not on Playstore' },
    { value: 65, label: 'QC loans inquiry' },
    { value: 67, label: 'Refunds' },
    { value: 68, label: 'Registration Issue' },
    { value: 69, label: 'Rejected loans QC' },
    { value: 70, label: 'Request A/C details GTB/Monnify' },
    { value: 71, label: 'Rude cc or cs' },
    { value: 72, label: 'Silent Call' },
    { value: 74, label: 'User information change' },
    { value: 75, label: 'Verification Code Issues' },
    { value: 77, label: 'Wrongful Debit' },
    { value: 80, label: 'Saving Issues' },
    { value: 81, label: 'Selfie Issues' },
    { value: 82, label: 'KYC Needed' },
    { value: 83, label: 'Bank Statement Issues' },
    { value: 84, label: '1 Stars' },
    { value: 85, label: '2 Stars' },
    { value: 86, label: '3 Stars' },
    { value: 87, label: '4 Stars' },
    { value: 88, label: 'Jumia Account Suspension' },
    { value: 89, label: 'Jumia BVN Validation issues' },
    { value: 90, label: 'Jumia Active loan extension' },
    { value: 91, label: 'Jumia Adding card issue' },
    { value: 92, label: 'Jumia App Freezing' },
    { value: 93, label: 'Jumia app location error' },
    { value: 94, label: 'Jumia Auto Debit complaint' },
    { value: 95, label: 'Jumia Auto Debit Request' },
    { value: 96, label: 'Jumia Bank statement issue' },
    { value: 97, label: "Jumia can't make payment via providus" },
    { value: 98, label: 'Jumia Canceled Loans' },
    { value: 99, label: 'Jumia Cant make payment via card' },
    { value: 100, label: 'Jumia Cant make payment via Monnify/GT' },
    { value: 101, label: 'Jumia Card Linked to other users' },
    { value: 102, label: 'Jumia Card removal request' },
    { value: 103, label: 'Jumia Credibuilder Complaints' },
    { value: 104, label: 'Jumia Death Certificate' },
    { value: 105, label: 'Jumia Disbursement complaint' },
    { value: 106, label: 'Jumia Disbursement inquiry' },
    { value: 107, label: 'Jumia Double debit via Card' },
    { value: 108, label: 'Jumia Double debit via Monify' },
    { value: 109, label: 'Jumia Double disbursal' },
    { value: 110, label: 'Jumia High Interests' },
    { value: 111, label: 'Jumia IOS compatible' },
    { value: 112, label: 'Jumia Kyc Needed' },
    { value: 113, label: 'Jumia Loan Extension STAGE A' },
    { value: 114, label: 'Jumia Loan extension STAGE B' },
    { value: 115, label: 'Jumia Loan extension STAGE C' },
    { value: 116, label: 'Jumia Loan extension STAGE D' },
    { value: 117, label: 'Jumia Loan extension STAGE E' },
    { value: 118, label: 'Jumia Loan extension STAGE F' },
    { value: 119, label: 'Jumia Loan extension Undefined STAGE' },
    { value: 120, label: 'Jumia Loan increment' },
    { value: 121, label: 'Jumia Loan Limit Reduction' },
    { value: 122, label: 'Jumia Loan Overpayment' },
    { value: 123, label: 'Jumia Loan payment complaint Providus' },
    { value: 124, label: 'Jumia Loan Payment Complaints flutterwave' },
    { value: 125, label: 'Jumia Loan Payment Complaints GTB' },
    { value: 126, label: 'Jumia Loan Payment Complaints monnify' },
    { value: 127, label: 'Jumia Loan Payment Complaints Paystack' },
    { value: 128, label: 'Jumia Loan Status Payment Inquiry' },
    { value: 129, label: 'Jumia Loan tenure issue' },
    { value: 130, label: 'Jumia Merged Accounts' },
    { value: 131, label: 'Jumia Mismatched Payment' },
    { value: 132, label: 'JUMIA No ATM Card' },
    { value: 133, label: 'Jumia OTP Request' },
    { value: 134, label: 'Jumia Part payment request' },
    { value: 135, label: 'Jumia Pin Reset Issue' },
    { value: 136, label: 'Jumia Promised to pay' },
    { value: 137, label: 'Jumia Refunds' },
    { value: 138, label: 'Jumia Request A/C details GTB/Monnify' },
    { value: 139, label: 'Jumia Selfie issue' },
    { value: 140, label: 'Jumia Stop Interests' },
    { value: 141, label: 'Jumia User information change' },
    { value: 142, label: 'Jumia Verification Code Issues' },
    { value: 143, label: 'Jumia Wrong email address' },
    { value: 144, label: 'Jumia Wrongful Debit' },
    { value: 145, label: 'QC Loan Payment Complaints GTB' },
    { value: 146, label: 'QC Account Suspension' },
    { value: 147, label: 'QC Active loan extension' },
    { value: 148, label: 'QC Adding card issue' },
    { value: 149, label: 'QC App freezing' },
    { value: 150, label: 'QC App issues' },
    { value: 151, label: 'QC App location error' },
    { value: 152, label: 'QC Auto Debit complaint' },
    { value: 153, label: 'QC Auto Debit Request' },
    { value: 154, label: 'QC Bank statement issue' },
    { value: 155, label: 'QC BVN Validation issues' },
    { value: 156, label: "QC can't make payment via providus" },
    { value: 157, label: 'QC Canceled Loans' },
    { value: 158, label: 'QC Cant make payment via card' },
    { value: 159, label: 'QC Cant make payment via Monnify/GT' },
    { value: 160, label: 'QC Card Linked to other users' },
    { value: 161, label: 'QC Card removal request' },
    { value: 162, label: 'QC Credibuilder Complaints' },
    { value: 163, label: 'QC Death Certificate' },
    { value: 164, label: 'QC Disbursement complaint' },
    { value: 165, label: 'QC Disbursement inquiry' },
    { value: 166, label: 'QC Double debit via Card' },
    { value: 167, label: 'QC Double debit via Monify' },
    { value: 168, label: 'QC Double disbursal' },
    { value: 169, label: 'QC High Interests' },
    { value: 170, label: 'QC IOS compatible' },
    { value: 171, label: 'QC Kyc Needed' },
    { value: 172, label: 'QC Loan Extension STAGE A' },
    { value: 173, label: 'QC Loan extension STAGE B' },
    { value: 174, label: 'QC Loan extension STAGE C' },
    { value: 175, label: 'QC Loan extension STAGE D' },
    { value: 176, label: 'QC Loan extension STAGE E' },
    { value: 177, label: 'QC Loan extension STAGE F' },
    { value: 178, label: 'QC Loan extension Undefined STAGE' },
    { value: 179, label: 'QC Loan increment' },
    { value: 180, label: 'QC Loan Limit Reduction' },
    { value: 181, label: 'QC Loan Overpayment' },
    { value: 182, label: 'QC Loan payment complaint Providus' },
    { value: 183, label: 'QC Loan Payment Complaints flutterwave' },
    { value: 184, label: 'QC Loan Payment Complaints monnify' },
    { value: 185, label: 'QC Loan Payment Complaints Paystack' },
    { value: 186, label: 'QC Loan Status Payment Inquiry' },
    { value: 187, label: 'QC Loan tenure issue' },
    { value: 188, label: 'QC login issues' },
    { value: 189, label: 'QC Merged Accounts' },
    { value: 190, label: 'QC Mismatched Payment' },
    { value: 191, label: 'QC No ATM Card' },
    { value: 192, label: 'QC OTP Request' },
    { value: 193, label: 'QC Part payment request' },
    { value: 194, label: 'QC Pin Reset Issue' },
    { value: 195, label: 'QC Promised to pay' },
    { value: 196, label: 'QC Refunds' },
    { value: 197, label: 'QC Rejected loans' },
    { value: 198, label: 'QC Request A/C details GTB/Monnify' },
    { value: 199, label: 'QC Selfie issue' },
    { value: 200, label: 'QC Stop Interests' },
    { value: 201, label: 'QC User information change' },
    { value: 202, label: 'QC Verification Code Issues' },
    { value: 203, label: 'QC Wrong email address' },
    { value: 204, label: 'QC Wrongful Debit' },
    { value: 205, label: 'Savings' },
    { value: 206, label: 'QC Loan Complaints' },
    { value: 207, label: 'Jumia Loan Complaints' },
    { value: 208, label: 'Inability to use the mobile app' },
    { value: 209, label: 'Low approved amount' },
];
const EVALUATION_FIELD_BASE = [
    { key: 'tag', value: { choices: exports.TAG_CHOICES, default: 55 } },
    { key: 'review_status', value: { choices: filterCallRecordConstants_1.STATUS_CHOICES, default: 0 } },
    { key: 'comments', value: {} },
    { key: 'opening_greeting', value: { choices: GREETINGS_CHOICES, default: 5 } },
    { key: 'closing_greeting', value: { choices: GREETINGS_CHOICES, default: 5 } },
    { key: 'tone_and_pace', value: { choices: TONE_AND_PEACE_CHOICES, default: 2 } },
    { key: 'interruption', value: { choices: INTERRUPTION_CHOICES, default: 5 } },
    { key: 'articulation', value: { choices: ARTICULATION_CHOICES, default: 5 } },
    { key: 'dead_air', value: { choices: DEAD_AIR_CHOICES, default: 3 } },
    { key: 'attentiveness', value: { choices: ATTENTIVENESS_CHOICES, default: 5 } },
    { key: 'polite', value: { choices: POLITE_CHOICES, default: 4 } },
    { key: 'empathy', value: { choices: EMPATHY_CHOICES, default: -1 } },
];
exports.DEMOGRAPHIC_FIELDS_KEYS = ['reason_for_delayed_payment', 'tag', 'review_status', 'comments'];
const EVALUATION_FIELD_CC = [
    ...EVALUATION_FIELD_BASE,
    { key: 'reason_for_delayed_payment', value: { choices: exports.DELAYED_PAYMENT_REASON_CC_CHOICES, default: 16 } },
    { key: 'rapport', value: { choices: RAPPORT_CHOICES, default: 8 } },
    { key: 'promise_to_pay', value: { choices: PROMISE_TO_PAY_CHOICES, default: 20 } },
    { key: 'payment_delay', value: { choices: PAYMENT_DELAY_CHOICES, default: -1 } },
    { key: 'call_history', value: { choices: CALL_HISTORY_CC_CHOICES, default: 3 } },
    { key: 'user_details', value: { choices: USER_DETAILS_CHOICES, default: 6 } },
    { key: 'loan_details', value: { choices: LOAN_DETAILS_CHOICES, default: 6 } },
    { key: 'call_tag', value: { choices: CALL_TAG_CHOICES, default: 5 } },
    { key: 'call_summary', value: { choices: CALL_SUMMARY_CHOICES, default: 5 } },
];
const EVALUATION_FIELD_CS = [
    ...EVALUATION_FIELD_BASE,
    { key: 'articulation', value: { choices: ARTICULATION_CS_CHOICES, default: 4 } },
    { key: 'attentiveness', value: { choices: ATTENTIVENESS_CS_CHOICES, default: 4 } },
    { key: 'rapport', value: { choices: RAPPORT_CHOICES, default: 8 } },
    { key: 'user_details', value: { choices: USER_DETAILS_CHOICES, default: 6 } },
    { key: 'loan_details', value: { choices: LOAN_DETAILS_CS_CHOICES, default: 12 } },
    { key: 'call_tag', value: { choices: CALL_TAG_CHOICES, default: 5 } },
    { key: 'call_summary', value: { choices: CALL_SUMMARY_CHOICES, default: 5 } },
    { key: 'hold_procedure', value: { choices: HOLD_PROCEDURE_CHOICES, default: 2 } },
    { key: 'issue_resolved', value: { choices: ISSUE_RESOLVED_CHOICES, default: 11 } },
    { key: 'issue_escalated', value: { choices: ISSUE_ESCALATED_CHOICES, default: -1 } },
];
const EVALUATION_FIELD_KAM = [
    ...EVALUATION_FIELD_BASE,
    { key: 'reason_for_delayed_payment', value: { choices: exports.DELAYED_PAYMENT_REASON_KAM_CHOICES, default: 11 } },
    { key: 'opening_greeting', value: { choices: GREETINGS_KAM_CHOICES, default: 6 } },
    { key: 'closing_greeting', value: { choices: GREETINGS_KAM_CHOICES, default: 6 } },
    { key: 'tone_and_pace', value: { choices: TONE_AND_PEACE_KAM_CHOICES, default: 4 } },
    { key: 'polite', value: { choices: POLITE_KAM_CHOICES, default: 6 } },
    { key: 'call_history', value: { choices: CALL_HISTORY_KAM_CHOICES, default: 6 } },
    { key: 'issue_resolved', value: { choices: ISSUE_RESOLVED_KAM_CHOICES, default: 16 } },
    { key: 'issue_escalated', value: { choices: ISSUE_ESCALATED_KAM_CHOICES, default: -1 } },
    { key: 'negotiation', value: { choices: NEGOTIATION_CHOCIES, default: -1 } },
];
exports.evaulationFieldsCC = new Map(EVALUATION_FIELD_CC.map(({ key, value }) => [key, value]));
exports.evaulationFieldsCS = new Map(EVALUATION_FIELD_CS.map(({ key, value }) => [key, value]));
exports.evaulationFieldsKAM = new Map(EVALUATION_FIELD_KAM.map(({ key, value }) => [key, value]));
exports.evaluationFields = new Map([
    [qualityAssuranceConstants_1.COLLECTIONS, exports.evaulationFieldsCC],
    [qualityAssuranceConstants_1.COLLECTIONS_ASSISTANT, exports.evaulationFieldsCC],
    [qualityAssuranceConstants_1.TO_SUPPORT, exports.evaulationFieldsCS],
    [qualityAssuranceConstants_1.FROM_SUPPORT, exports.evaulationFieldsCS],
    [qualityAssuranceConstants_1.KEY_ACCOUNT, exports.evaulationFieldsKAM],
]);
