"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MANUAL_CONFIRMATION_REJECTION_REASONS = exports.REJECT_LOAN_ACTION_KEY = exports.OTHER_LOANS = exports.REPAYMENTS = exports.INSTALMENTS = exports.LOAN_DETAILS = void 0;
exports.LOAN_DETAILS = 'LOAN_DETAILS';
exports.INSTALMENTS = 'INSTALMENTS';
exports.REPAYMENTS = 'REPAYMENTS';
exports.OTHER_LOANS = 'OTHER_LOANS';
exports.REJECT_LOAN_ACTION_KEY = '1';
exports.MANUAL_CONFIRMATION_REJECTION_REASONS = [
    { value: '', label: 'Select a rejection reason' },
    { value: 1, label: 'Flagged address' },
    { value: 2, label: 'Phony email address' },
    { value: 3, label: 'Unable to confirm disbursal bank account' },
    { value: 5, label: 'Could not supply registration details without help of 3rd party' },
    { value: 6, label: 'Paid 3rd party before requesting or will pay after disbursal' },
    { value: 7, label: 'User was not aware of the loan' },
    { value: 8, label: 'User could not confirm loan amount' },
    { value: 10, label: 'User could not confirm repayment date/loan tenure' },
    { value: 11, label: 'User could not confirm repayment amount' },
];
