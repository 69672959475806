"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CALL_WITH_ISSUES_CHOICES = exports.TIMER_START_VALUE = exports.INVALID_DURATION_INPUT_ERROR_TEXT = exports.DURATION_TIME_FORMAT_REGEX = void 0;
exports.DURATION_TIME_FORMAT_REGEX = /^(?:2[0-3]|[01]?[0-9]):[0-5][0-9]:[0-5][0-9]$/;
exports.INVALID_DURATION_INPUT_ERROR_TEXT = 'Invalid duration input. It has to be in the format HH:MM:SS';
exports.TIMER_START_VALUE = '00:00:00';
exports.CALL_WITH_ISSUES_CHOICES = [
    { value: 4, label: 'Call dropped' },
    { value: 5, label: "Customer couldn't hear me" },
    { value: 6, label: "I couldn't hear customer" },
    { value: 7, label: "Call didn't connect" },
    { value: 8, label: 'Other' },
];
