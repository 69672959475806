"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetOfficerSchedulesState = exports.getOfficerSchedules = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const typesConstants_1 = require("../../constants/typesConstants");
const network_1 = require("../../utils/network");
const utils_1 = require("../../utils/utils");
const schedulesConstants_1 = require("./schedulesConstants");
exports.getOfficerSchedules = toolkit_1.createAsyncThunk('schedules/getOfficerSchedules', async ({ officerId, page }, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        const today = new Date().toISOString().split('T')[0];
        const queryParams = utils_1.encodeUrlParams({
            datetime_created__date: today,
            status: String(schedulesConstants_1.SCHEDULE_STATUS_COMPLETED),
            credit_officer: officerId,
            ...(page && { page: String(page) }),
        });
        const urlPath = `${schedulesConstants_1.OFFICER_SCHEDULES_API_PATH}?${queryParams}` +
            `&status=${schedulesConstants_1.SCHEDULE_STATUS_RESCHEDULED}` +
            `&status=${schedulesConstants_1.SCHEDULE_STATUS_RECYCLE}` +
            `&status=${schedulesConstants_1.SCHEDULE_STATUS_STARTED}`;
        return await network_1.get(urlPath, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        return network_1.handleDefaultError(err, rejectWithValue);
    }
});
const initialState = {
    schedules: [],
    loadingStatus: typesConstants_1.PENDING_STATUS,
    currentPage: null,
    nextPage: null,
    previousPage: null,
    totalPages: null,
    totalEntries: null,
    pageSize: 10,
};
const officerSchedulesSlice = toolkit_1.createSlice({
    name: 'officerSchedules',
    initialState,
    reducers: {
        resetOfficerSchedulesState() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exports.getOfficerSchedules.pending, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.getOfficerSchedules.fulfilled, (state, { payload }) => ({
            ...state,
            loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
            schedules: payload.results,
            currentPage: payload.current,
            nextPage: payload.next,
            previousPage: payload.previous,
            totalPages: payload.total_pages,
            totalEntries: payload.total_entries,
            pageSize: payload.page_size,
        }))
            .addCase(exports.getOfficerSchedules.rejected, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.FAILED_STATUS,
        }));
    },
});
exports.resetOfficerSchedulesState = officerSchedulesSlice.actions.resetOfficerSchedulesState;
exports.default = officerSchedulesSlice.reducer;
