"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setDialog = exports.dismissDialog = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    dialog: null,
};
const commonSlice = toolkit_1.createSlice({
    name: 'common',
    initialState,
    reducers: {
        dismissDialog(state) {
            return { ...state, dialog: null };
        },
        setDialog(state, { payload }) {
            return {
                ...state,
                dialog: {
                    dialogAction: payload.actionElement,
                    dialogText: payload.text,
                    dialogTitle: payload.title,
                    confirmationDialogVisible: true,
                },
            };
        },
    },
    extraReducers: () => { },
});
_a = commonSlice.actions, exports.dismissDialog = _a.dismissDialog, exports.setDialog = _a.setDialog;
exports.default = commonSlice.reducer;
