"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MEDIA_CONSTRAINTS = exports.PHONE_NUMBER_REGEX = exports.MAXIMUM_PHONE_NUMBER_LENGTH = exports.MINIMUM_PHONE_NUMBER_LENGTH = exports.UUID_REGEX = exports.NUMBER_TOO_LONG_ERROR = exports.NUMBER_TOO_SHORT_ERROR = exports.ONLY_DIGIT_REGEX = exports.NUMBER_INVALID_ERROR = exports.VEZETI_VOIP_PROVIDER = exports.AIRTEL_VOIP_PROVIDER = exports.INBOUND_CALL = exports.VOIP_CALL = exports.SELECT_PROVIDER = exports.CALL_TYPE_REGULAR = exports.CALL_TYPE_VOIP = exports.CONNECTION_ERROR = exports.PAUSE_TIME = exports.CONNECTION_FAILED = exports.AWAITING_CALLS = exports.CONNECTED_TO_SERVER = exports.TRYING_TO_CONNECT = exports.COUNTRY_CODE = exports.OUTGOING_CALL = exports.INCOMING_CALL = exports.DISCONNECTED = exports.CONFIRMED = exports.ENDED = exports.FAILED = exports.CONNECTING = exports.CONNECTED = exports.IDLE = exports.SMS = exports.REGULAR = exports.BASE = exports.LOADING = void 0;
exports.LOADING = 'LOADING';
exports.BASE = 'BASE';
exports.REGULAR = 'REGULAR';
exports.SMS = 'SMS';
exports.IDLE = 'IDLE';
exports.CONNECTED = 'CONNECTED';
exports.CONNECTING = 'CONNECTING';
exports.FAILED = 'FAILED';
exports.ENDED = 'ENDED';
exports.CONFIRMED = 'CONFIRMED';
exports.DISCONNECTED = 'DISCONNECTED';
exports.INCOMING_CALL = 'remote';
exports.OUTGOING_CALL = 'local';
exports.COUNTRY_CODE = '+234';
exports.TRYING_TO_CONNECT = `trying to connect to server`;
exports.CONNECTED_TO_SERVER = `connected to server. Have a nice experience!`;
exports.AWAITING_CALLS = 'Connected, awaiting incoming calls';
exports.CONNECTION_FAILED = `connection to server failed.`;
exports.PAUSE_TIME = `you are currently paused and you wouldn't get inbound calls.`;
exports.CONNECTION_ERROR = 'ERROR: Can’t establish a connection to the server';
exports.CALL_TYPE_VOIP = 0;
exports.CALL_TYPE_REGULAR = 1;
// Voip call provider
exports.SELECT_PROVIDER = 'SELECT_PROVIDER';
exports.VOIP_CALL = 'VOIP_CALL';
exports.INBOUND_CALL = 'INBOUND_CALL';
exports.AIRTEL_VOIP_PROVIDER = { title: 'Airtel', value: 'airtelng' };
exports.VEZETI_VOIP_PROVIDER = { title: 'Vezeti', value: 'vezeti' };
exports.NUMBER_INVALID_ERROR = 'The number is not formatted properly. Only digits are allowed.';
exports.ONLY_DIGIT_REGEX = /^\d*$/;
exports.NUMBER_TOO_SHORT_ERROR = 'The phone number must be at least 9 digits.';
exports.NUMBER_TOO_LONG_ERROR = 'The phone number must not be longer than 13 digits.';
exports.UUID_REGEX = /"([^"]+)"/;
exports.MINIMUM_PHONE_NUMBER_LENGTH = 8;
exports.MAXIMUM_PHONE_NUMBER_LENGTH = 13;
exports.PHONE_NUMBER_REGEX = new RegExp(`(?:<|\\b)(\\d{${exports.MINIMUM_PHONE_NUMBER_LENGTH},})(?:>|\\b)`);
exports.MEDIA_CONSTRAINTS = { mediaConstraints: { audio: true, video: false } };
