"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const styles = react_native_1.StyleSheet.create({
    sizeBox: {
        minWidth: react_native_1.Dimensions.get('window').width * 0.5,
        minHeight: react_native_1.Dimensions.get('window').height * 0.4,
        padding: react_native_1.Dimensions.get('window').width * 0.1,
    },
    text: {
        fontWeight: 'bold',
        fontSize: 25,
    },
});
exports.default = styles;
