"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const colors = {
    BACKGROUND_COLOR: '#F5F5F5',
    BOXES_COLOR: '#F5F5F5',
    FONT_BLACK: '#262626',
    FONT_RED: '#F5222D',
    FONT_GRAY: '#8C8C8C',
    GRAY_2: '#D9D9D9',
    GRAY_3: '#BFBFBF',
    BORDER_COLOR: '#BFBFBF',
    WHITE: '#FFF',
    BLUE: '#1890FF',
    BLUE_2: '#004A8F',
    YELLOW: '#FFEF98',
    RED: '#D33F49',
    GREEN: '#40FFCF',
    BROWN: '#964B00',
    PURPLE: '#800080',
    GREEN_FLUORESCENT: '#40FFCF',
    GRAY_CONTAINER_COLOR: '#F5F5F5',
};
exports.default = colors;
