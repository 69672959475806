"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STATUS_LABELS = exports.STATUS_COLORS = exports.QUEUE_MEMBER = exports.QUEUE_PARAMS = exports.NOTIFICATION = exports.SCHEDULE = exports.QUEUE_MEMBER_STATUS = exports.STATUS_ON_HOLD = exports.STATUS_RINGING_AND_IN_USE = exports.STATUS_RINGING = exports.STATUS_UNAVAILABLE = exports.STATUS_INVALID = exports.STATUS_BUSY = exports.STATUS_IN_USE = exports.STATUS_NOT_IN_USE = exports.STATUS_VALID_BUT_UNKNOWN = exports.CONNECTION_OPEN = void 0;
const EVENT_PROTOCOL = 'json';
exports.CONNECTION_OPEN = '-1';
exports.STATUS_VALID_BUT_UNKNOWN = '0';
exports.STATUS_NOT_IN_USE = '1';
exports.STATUS_IN_USE = '2';
exports.STATUS_BUSY = '3';
exports.STATUS_INVALID = '4';
exports.STATUS_UNAVAILABLE = '5';
exports.STATUS_RINGING = '6';
exports.STATUS_RINGING_AND_IN_USE = '7';
exports.STATUS_ON_HOLD = '8';
exports.QUEUE_MEMBER_STATUS = 'QueueMemberStatus';
exports.SCHEDULE = 'Schedule';
exports.NOTIFICATION = 'Notification';
exports.QUEUE_PARAMS = 'QueueParams';
exports.QUEUE_MEMBER = 'QueueMember';
exports.STATUS_COLORS = new Map([
    [exports.CONNECTION_OPEN, '#FFFF00'],
    [exports.STATUS_VALID_BUT_UNKNOWN, '#FF0000'],
    [exports.STATUS_NOT_IN_USE, '#00FF00'],
    [exports.STATUS_IN_USE, '#0000FF'],
    [exports.STATUS_BUSY, '#FF00FF'],
    [exports.STATUS_INVALID, '#00FFFF'],
    [exports.STATUS_UNAVAILABLE, '#000000'],
    [exports.STATUS_RINGING, '#FFFFFF'],
    [exports.STATUS_RINGING_AND_IN_USE, '#808080'],
    [exports.STATUS_ON_HOLD, '#800080'], // Purple
]);
exports.STATUS_LABELS = new Map([
    [exports.CONNECTION_OPEN, 'Open Connection'],
    [exports.STATUS_VALID_BUT_UNKNOWN, 'Valid But Unknown'],
    [exports.STATUS_NOT_IN_USE, 'Not In Use'],
    [exports.STATUS_IN_USE, 'In Use'],
    [exports.STATUS_BUSY, 'Busy'],
    [exports.STATUS_INVALID, 'Invalid'],
    [exports.STATUS_UNAVAILABLE, 'Unavailable'],
    [exports.STATUS_RINGING, 'Ringing'],
    [exports.STATUS_RINGING_AND_IN_USE, 'Ringing And In Use'],
    [exports.STATUS_ON_HOLD, 'On Hold'],
]);
exports.default = EVENT_PROTOCOL;
