"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CDR_FILE_AVAILABILITY_CHOICES = exports.CALL_TYPE_CHOICES = exports.STATUS_CHOICES = void 0;
const qualityAssuranceConstants_1 = require("../../features/qualityAssurance/qualityAssuranceConstants");
const DISPOSITION_CHOICES = [
    { value: '', label: 'Please select a disposition' },
    { value: 'BUSY', label: 'Busy' },
    { value: 'ANSWERED', label: 'Answered' },
    { value: 'NO ANSWER', label: 'No Answer' },
];
exports.STATUS_CHOICES = [
    { value: '', label: 'Please select a status' },
    { value: qualityAssuranceConstants_1.NOT_REVIEWED, label: 'Not Reviewed' },
    { value: qualityAssuranceConstants_1.REVIEWED, label: 'Reviewed' },
    { value: qualityAssuranceConstants_1.NETWORK_ISSUE, label: 'Network Issue' },
    { value: qualityAssuranceConstants_1.TECHNICAL_ISSUE, label: 'Technical Issue' },
    { value: qualityAssuranceConstants_1.DROPPED, label: 'Call Dropped' },
];
exports.CALL_TYPE_CHOICES = [
    { value: '', label: 'Please select a call type' },
    { value: qualityAssuranceConstants_1.COLLECTIONS, label: 'Collections' },
    { value: qualityAssuranceConstants_1.TO_SUPPORT, label: 'To Support' },
    { value: qualityAssuranceConstants_1.FROM_SUPPORT, label: 'From Support' },
    { value: qualityAssuranceConstants_1.KEY_ACCOUNT, label: 'Key Account' },
    { value: qualityAssuranceConstants_1.COLLECTIONS_ASSISTANT, label: 'Collection Assistant' },
    { value: qualityAssuranceConstants_1.IVR, label: 'IVR' },
];
exports.CDR_FILE_AVAILABILITY_CHOICES = [
    { value: '', label: 'Select file availability' },
    { value: 1, label: 'No file' },
    { value: 2, label: 'With file' },
];
exports.default = DISPOSITION_CHOICES;
