"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleScheduleMode = exports.updateVoipProvider = exports.setInitialState = exports.invalidSessionToken = exports.logout = exports.updateOfficerDefaultedLoanActive = exports.login = exports.loginActionType = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_native_1 = require("react-native");
const constants_1 = require("../../constants/constants");
const typesConstants_1 = require("../../constants/typesConstants");
const network_1 = require("../../utils/network");
const authConstants_1 = __importStar(require("./authConstants"));
const initialState = {
    status: typesConstants_1.IDLE_STATUS,
    token: '',
    invalidToken: false,
    message: '',
    app_version_status: '',
    user: null,
};
exports.loginActionType = 'auth/login';
exports.login = toolkit_1.createAsyncThunk(exports.loginActionType, async ({ username, password, app_version }, { rejectWithValue }) => {
    try {
        let body = {};
        if (app_version) {
            body = {
                username,
                password,
                app_version,
            };
        }
        else {
            body = {
                username,
                password,
            };
        }
        return await network_1.post(authConstants_1.default, '', body)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.updateOfficerDefaultedLoanActive = toolkit_1.createAsyncThunk('auth/is_defaulted_loan_active', async ({ isDefaultedLoanActive }, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        return await network_1.post(authConstants_1.UPDATE_IS_DEFAULTED_LOAN_ACTIVE_URL, auth.token, {
            is_defaulted_loan_active: isDefaultedLoanActive,
        })
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
const authSlice = toolkit_1.createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout(state) {
            return {
                ...state,
                token: '',
                message: '',
                errorMessage: undefined,
            };
        },
        invalidSessionToken(state) {
            return {
                ...state,
                token: '',
                errorMessage: undefined,
                message: 'Your session has expired. Please sign in again.',
            };
        },
        setInitialState(state) {
            return { ...initialState, token: state.token };
        },
        updateVoipProvider(state, { payload }) {
            const voipProvider = state.user.voip_providers.find((e) => e.backend_value === payload.backend_value);
            return { ...state, user: { ...state.user, current_voip_provider: voipProvider } };
        },
        toggleScheduleMode(state) {
            return { ...state, user: { ...state.user, schedule_mode: !state.user.schedule_mode } };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exports.login.pending, (state) => ({
            ...state,
            status: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.login.rejected, (state, { payload }) => ({
            ...state,
            token: '',
            status: typesConstants_1.FAILED_STATUS,
            message: '',
            errorMessage: payload.message,
            errors: payload.errorObject,
        }))
            .addCase(exports.login.fulfilled, (state, { payload }) => ({
            ...state,
            token: payload.token,
            user: payload.user && {
                ...payload.user,
                // Set current provider to the partner default (Occurs only during sign in).
                current_voip_provider: payload.user.voip_providers.find((e) => e.backend_value === payload.user.default_voip_provider),
                // Set the officer schedule mode.
                schedule_mode: typesConstants_1.OFFICER_TYPE_WITH_SCHEDULES.includes(payload.user.officer_type),
            },
            status: typesConstants_1.SUCCEEDED_STATUS,
            invalidToken: false,
            errorMessage: undefined,
            errors: undefined,
            message: '',
        }))
            .addCase(exports.updateOfficerDefaultedLoanActive.pending, (state) => ({
            ...state,
            status: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.updateOfficerDefaultedLoanActive.rejected, (state, { payload }) => ({
            ...state,
            status: typesConstants_1.FAILED_STATUS,
            errorMessage: payload.message,
            errors: payload.errorObject,
        }))
            .addCase(exports.updateOfficerDefaultedLoanActive.fulfilled, (state, { payload }) => ({
            ...state,
            user: { ...state.user, ...payload },
            status: typesConstants_1.SUCCEEDED_STATUS,
        }));
    },
});
_a = authSlice.actions, exports.logout = _a.logout, exports.invalidSessionToken = _a.invalidSessionToken, exports.setInitialState = _a.setInitialState, exports.updateVoipProvider = _a.updateVoipProvider, exports.toggleScheduleMode = _a.toggleScheduleMode;
exports.default = authSlice.reducer;
