"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DATE_COMPONENT_TYPE = exports.TITLE_COMPONENT_TYPE = exports.CUSTOM_COMPONENT_TYPE = exports.AMOUNT_COMPONENT_TYPE = exports.EXTERNAL_ID_TYPE_USER = exports.EXTERNAL_ID_TYPE_LOAN = exports.BROWSER_NOTIFICATION_DEFAULT = exports.BROWSER_NOTIFICATION_DENIED = exports.BROWSER_NOTIFICATION_GRANTED = exports.SCHEDULE_CATEGORY_MANUAL_CONFIRMATION = exports.SCHEDULING_TYPE_AUTOMATED = exports.SCHEDULING_TYPE_MANUAL = exports.AUTOMATED_ANSWER_OFFICER_TYPES = exports.OFFICER_TYPE_WITH_SCHEDULES = exports.OFFICER_TYPES_TELE_SALES = exports.OFFICER_TYPES_PRE_QUALIFICATION = exports.OFFICER_TYPES_TELE_COLLECTION_ASSISTANT = exports.OFFICER_TYPES_KEY_ACCOUNT = exports.OFFICER_TYPES_CUSTOMER_SUPPORT = exports.OFFICER_TYPES_QUALITY_ANALYST = exports.OFFICER_TYPES_FIELD_RECOVERY = exports.OFFICER_TYPES_TELE_COLLECTION = exports.RED_FLAG_ACTION_TYPE = exports.ESCALATE_TO_RECOVERY_ACTION_TYPE = exports.CUSTOM_NOTES_ACTION_TYPE = exports.DO_NOT_CALL_ACTION_TYPE = exports.DELETE_INSTALMENT_ACTION_TYPE = exports.LOG_CALL_ACTION_TYPE = exports.CUSTOM_ACTION_TYPE = exports.SMS_ACTION_TYPE = exports.SUCCEEDED_STATUS = exports.FAILED_STATUS = exports.PENDING_STATUS = exports.IDLE_STATUS = void 0;
exports.IDLE_STATUS = 'idle';
exports.PENDING_STATUS = 'pending';
exports.FAILED_STATUS = 'failed';
exports.SUCCEEDED_STATUS = 'succeeded';
exports.SMS_ACTION_TYPE = 'sendSMS';
exports.CUSTOM_ACTION_TYPE = 'customAction';
exports.LOG_CALL_ACTION_TYPE = 'logCall';
exports.DELETE_INSTALMENT_ACTION_TYPE = 'deleteInstalment';
exports.DO_NOT_CALL_ACTION_TYPE = 'flagAsDoNotCall';
exports.CUSTOM_NOTES_ACTION_TYPE = 'customNotes';
exports.ESCALATE_TO_RECOVERY_ACTION_TYPE = 'escalateRecovery';
exports.RED_FLAG_ACTION_TYPE = 'redFlag';
exports.OFFICER_TYPES_TELE_COLLECTION = 0;
exports.OFFICER_TYPES_FIELD_RECOVERY = 1;
exports.OFFICER_TYPES_QUALITY_ANALYST = 2;
exports.OFFICER_TYPES_CUSTOMER_SUPPORT = 3;
exports.OFFICER_TYPES_KEY_ACCOUNT = 4;
exports.OFFICER_TYPES_TELE_COLLECTION_ASSISTANT = 5;
exports.OFFICER_TYPES_PRE_QUALIFICATION = 6;
exports.OFFICER_TYPES_TELE_SALES = 7;
exports.OFFICER_TYPE_WITH_SCHEDULES = [
    exports.OFFICER_TYPES_TELE_COLLECTION,
    exports.OFFICER_TYPES_TELE_COLLECTION_ASSISTANT,
    exports.OFFICER_TYPES_KEY_ACCOUNT,
];
exports.AUTOMATED_ANSWER_OFFICER_TYPES = [
    exports.OFFICER_TYPES_TELE_COLLECTION,
    exports.OFFICER_TYPES_KEY_ACCOUNT,
    exports.OFFICER_TYPES_CUSTOMER_SUPPORT,
];
exports.SCHEDULING_TYPE_MANUAL = 0;
exports.SCHEDULING_TYPE_AUTOMATED = 1;
exports.SCHEDULE_CATEGORY_MANUAL_CONFIRMATION = 5;
exports.BROWSER_NOTIFICATION_GRANTED = 'granted';
exports.BROWSER_NOTIFICATION_DENIED = 'denied';
exports.BROWSER_NOTIFICATION_DEFAULT = 'default';
exports.EXTERNAL_ID_TYPE_LOAN = 0;
exports.EXTERNAL_ID_TYPE_USER = 1;
exports.AMOUNT_COMPONENT_TYPE = 'amount';
exports.CUSTOM_COMPONENT_TYPE = 'custom';
exports.TITLE_COMPONENT_TYPE = 'title';
exports.DATE_COMPONENT_TYPE = 'date';
