"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateSearchQuery = exports.resetCallState = exports.commentOnCallDetailRecord = exports.getCallLogs = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const constants_1 = require("../../constants/constants");
const typesConstants_1 = require("../../constants/typesConstants");
const network_1 = require("../../utils/network");
const utils_1 = require("../../utils/utils");
const callConstant_1 = __importStar(require("./callConstant"));
exports.getCallLogs = toolkit_1.createAsyncThunk('call/call_logs', async ({ page, destination, id, disposition }, { getState, rejectWithValue }) => {
    try {
        const queryParams = utils_1.encodeUrlParams({
            ...(page && { page: String(page) }),
            ...(destination && { destination }),
            ...(disposition && { disposition__iexact: disposition }),
            ...(id && { id }),
        });
        const { auth } = getState();
        const urlPath = `${callConstant_1.default}?${queryParams}`;
        return await network_1.get(urlPath, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        return network_1.handleDefaultError(err, rejectWithValue);
    }
});
exports.commentOnCallDetailRecord = toolkit_1.createAsyncThunk('call/comment', async ({ id, comment }, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        const url = callConstant_1.CALL_DETAIL_RECORD_COMMENT_PATH.replace(constants_1.CALL_DETAIL_RECORD_ID, id);
        return await network_1.fetchBackend(url, constants_1.HTTP.PATCH, auth.token, { id, comment })
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        return network_1.handleDefaultError(err, rejectWithValue);
    }
});
const initialState = {
    callRecords: [],
    status: typesConstants_1.PENDING_STATUS,
    currentPage: null,
    nextPage: null,
    previousPage: null,
    totalPages: null,
    totalEntries: null,
    pageSize: 0,
};
const callSlice = toolkit_1.createSlice({
    name: 'call',
    initialState,
    reducers: {
        resetCallState() {
            return initialState;
        },
        updateSearchQuery(state, { payload }) {
            return { ...state, searchQuery: payload };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exports.getCallLogs.pending, (state) => ({
            ...state,
            status: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.getCallLogs.fulfilled, (state, { payload }) => ({
            ...state,
            status: typesConstants_1.SUCCEEDED_STATUS,
            callRecords: payload.results,
            currentPage: payload.current,
            nextPage: payload.next,
            previousPage: payload.previous,
            totalPages: payload.total_pages,
            totalEntries: payload.total_entries,
            pageSize: payload.page_size,
        }))
            .addCase(exports.getCallLogs.rejected, (state, { payload }) => {
            const newState = { ...state };
            newState.status = typesConstants_1.FAILED_STATUS;
            newState.errorMessage = payload.message;
            return newState;
        })
            .addCase(exports.commentOnCallDetailRecord.pending, (state) => ({
            ...state,
            status: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.commentOnCallDetailRecord.fulfilled, (state, { payload }) => ({
            ...state,
            status: typesConstants_1.SUCCEEDED_STATUS,
            callRecords: state.callRecords.map((callRecord) => callRecord.id === payload.id ? payload : callRecord),
        }))
            .addCase(exports.commentOnCallDetailRecord.rejected, (state, { payload }) => {
            const newState = { ...state };
            newState.status = typesConstants_1.FAILED_STATUS;
            newState.errorMessage = payload.message;
            return newState;
        });
    },
});
_a = callSlice.actions, exports.resetCallState = _a.resetCallState, exports.updateSearchQuery = _a.updateSearchQuery;
exports.default = callSlice.reducer;
