"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const PAYMENT_DELAY_REASON_CHOICES = [
    { value: 0, label: 'Delayed salary' },
    { value: 1, label: 'Change of job' },
    { value: 2, label: 'Childbirth' },
    { value: 3, label: 'Ill health' },
    { value: 4, label: 'Incorrect profiling' },
    { value: 5, label: 'Issues with employer' },
    { value: 6, label: 'Previous issue' },
    { value: 7, label: 'Reduction in income' },
    { value: 8, label: 'Customer hung up' },
    { value: 9, label: 'Dispute' },
    { value: 10, label: 'Loss of employment' },
    { value: 11, label: 'No capacity to pay' },
    { value: 12, label: 'No intention to pay' },
    { value: 13, label: 'Service issue' },
    { value: 14, label: 'Language Barrier' },
    { value: 15, label: 'Fraud Case' },
    { value: 16, label: 'Deceased' },
    { value: 17, label: 'Others' },
];
exports.default = PAYMENT_DELAY_REASON_CHOICES;
