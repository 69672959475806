"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CAMPAIGN_SEARCH_PARAMETERS = exports.FULL_NAME_SEARCH_PAREMETERS = exports.CALL_LOG_SEARCH_PARAMETERS = exports.SEARCH_CAMPAIGN_QUEUE = exports.SEARCH_CAMPAIGN_NAME = exports.SEARCH_LAST_NAME = exports.SEARCH_FIRST_NAME = exports.SEARCH_DISPOSITION_PARAMETER = exports.SEARCH_ID_PARAMETER = exports.SEARCH_DESTINATION_PARAMETER = void 0;
exports.SEARCH_DESTINATION_PARAMETER = 'destination';
exports.SEARCH_ID_PARAMETER = 'id';
exports.SEARCH_DISPOSITION_PARAMETER = 'disposition';
exports.SEARCH_FIRST_NAME = 'first_name';
exports.SEARCH_LAST_NAME = 'last_name';
exports.SEARCH_CAMPAIGN_NAME = 'name';
exports.SEARCH_CAMPAIGN_QUEUE = 'queue';
exports.CALL_LOG_SEARCH_PARAMETERS = [
    exports.SEARCH_DESTINATION_PARAMETER,
    exports.SEARCH_ID_PARAMETER,
    exports.SEARCH_DISPOSITION_PARAMETER,
];
exports.FULL_NAME_SEARCH_PAREMETERS = [exports.SEARCH_FIRST_NAME, exports.SEARCH_LAST_NAME];
exports.CAMPAIGN_SEARCH_PARAMETERS = [exports.SEARCH_CAMPAIGN_NAME, exports.SEARCH_CAMPAIGN_QUEUE];
