"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const schedulesSlice_1 = require("../../features/schedules/schedulesSlice");
const screenConstants_1 = require("../../constants/screenConstants");
const constants_1 = require("../../constants/constants");
const notificationConstants_1 = require("../../features/notifications/notificationConstants");
const notificationSlice_1 = require("../../features/notifications/notificationSlice");
// Function to process a notification (mark as read and navigate to schedule)
const processNotification = (notification, dispatch, navigator) => {
    // Mark as read
    dispatch(notificationSlice_1.updateNotification({ notification_id: notification.id, read: true }));
    // Redirect to schedule detail view if scheduleID is provided as object_id
    if (notification.content_type === notificationConstants_1.SCHEDULE_CONTENT_TYPE && notification.object_id) {
        const scheduleId = notification.object_id;
        // Fetch the schedule and navigate to the schedule screen if successful
        dispatch(schedulesSlice_1.getSchedule({ scheduleId })).then((response) => {
            if (response.meta.requestStatus === constants_1.FULFILLED_RESPONSE_CONSTANT) {
                navigator.navigate(screenConstants_1.SCHEDULE_SCREEN, { schedule_id: scheduleId });
            }
        });
    } // Redirect to ticket detail view if ticketID is provided in reminder
    else if (notification.content_type === notificationConstants_1.TICKET_CONTENT_TYPE && notification.object_id) {
        const ticketId = notification.object_id;
        // Navigate to the ticket detail view
        navigator.navigate(screenConstants_1.TICKET_DETAIL_SCREEN, { ticket_id: ticketId });
    }
};
exports.default = processNotification;
