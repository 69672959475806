"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FILTER_CONTENT_TYPE_CHOICES = exports.FILTER_READ_STATUS_CHOICES = exports.NOTIFICATION_TITLES = exports.NOTIFICATION_TYPE_ICONS = exports.TICKET_CONTENT_TYPE = exports.SCHEDULE_CONTENT_TYPE = exports.NOTIFICATIONS_DETAIL_OR_UPDATE_URL = exports.NOTIFICATIONS_CREATE_OR_LIST_URL = void 0;
const constants_1 = require("../../constants/constants");
exports.NOTIFICATIONS_CREATE_OR_LIST_URL = `notifications/`;
exports.NOTIFICATIONS_DETAIL_OR_UPDATE_URL = `notifications/${constants_1.NOTIFICATION_ID_TAG}/`;
exports.SCHEDULE_CONTENT_TYPE = 'schedule';
exports.TICKET_CONTENT_TYPE = 'ticket';
exports.NOTIFICATION_TYPE_ICONS = new Map([[exports.SCHEDULE_CONTENT_TYPE, 'phone'], [exports.TICKET_CONTENT_TYPE, 'ticket']]);
exports.NOTIFICATION_TITLES = new Map([
    [exports.SCHEDULE_CONTENT_TYPE, 'Call Notification'],
    [exports.TICKET_CONTENT_TYPE, 'Ticket Assignment'],
]);
exports.FILTER_READ_STATUS_CHOICES = [
    { value: '', label: 'Filter by read status' },
    { value: 'true', label: 'Read' },
    { value: 'false', label: 'Unread' },
];
exports.FILTER_CONTENT_TYPE_CHOICES = [
    { value: '', label: 'Filter by notification type' },
    { value: exports.SCHEDULE_CONTENT_TYPE, label: 'Rescheduled Calls' },
    { value: exports.TICKET_CONTENT_TYPE, label: 'Ticket Assignment' },
];
