"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseInboundUUID = exports.parseInboundContact = exports.validatePhoneNumber = exports.formatPhoneNumber = exports.convertTimeToSeconds = void 0;
const utils_1 = require("../../utils/utils");
const communicationBoxConstants_1 = require("./communicationBoxConstants");
const convertTimeToSeconds = (time) => {
    // zero seconds for an empty string.
    if (time === '') {
        return 0;
    }
    const timeElements = time.split(':');
    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    return parseInt(timeElements[0], 10) * 60 * 60 + parseInt(timeElements[1], 10) * 60 + parseInt(timeElements[2], 10);
};
exports.convertTimeToSeconds = convertTimeToSeconds;
const formatPhoneNumber = (phoneNumber) => {
    let formattedPhoneNumber;
    // Check the length of the phonenumber.
    if (phoneNumber.length < 4) {
        // Phone starts with a partial area code so it is fine to replace.
        formattedPhoneNumber = '+234';
    }
    else if (phoneNumber.startsWith('234')) {
        formattedPhoneNumber = `+${phoneNumber}`;
    }
    else if (phoneNumber.startsWith('+234')) {
        formattedPhoneNumber = phoneNumber;
    }
    else if (phoneNumber.startsWith('0') && phoneNumber.length > communicationBoxConstants_1.MINIMUM_PHONE_NUMBER_LENGTH) {
        formattedPhoneNumber = `+234${phoneNumber.slice(1)}`;
    }
    else {
        formattedPhoneNumber = `+234${phoneNumber}`;
    }
    return formattedPhoneNumber;
};
exports.formatPhoneNumber = formatPhoneNumber;
const validatePhoneNumber = (phoneNumber) => {
    let error = '';
    // Clean phone number
    const cleanedPhoneNumber = phoneNumber.replace(/^\+/, '');
    // Confirm phone number only contains digits
    if (!communicationBoxConstants_1.ONLY_DIGIT_REGEX.test(cleanedPhoneNumber)) {
        error = communicationBoxConstants_1.NUMBER_INVALID_ERROR;
    }
    // Validate length.
    else if (cleanedPhoneNumber.length < communicationBoxConstants_1.MINIMUM_PHONE_NUMBER_LENGTH) {
        error = communicationBoxConstants_1.NUMBER_TOO_SHORT_ERROR;
    }
    else if (cleanedPhoneNumber.length > communicationBoxConstants_1.MAXIMUM_PHONE_NUMBER_LENGTH) {
        error = communicationBoxConstants_1.NUMBER_TOO_LONG_ERROR;
    }
    return error;
};
exports.validatePhoneNumber = validatePhoneNumber;
const parseInboundContact = (incomingContact) => {
    const numberList = incomingContact.match(communicationBoxConstants_1.PHONE_NUMBER_REGEX) || [];
    if (numberList && numberList[1]) {
        return exports.formatPhoneNumber(numberList[1]);
    }
    return incomingContact;
};
exports.parseInboundContact = parseInboundContact;
const parseInboundUUID = (incomingContact) => {
    const match = incomingContact.match(communicationBoxConstants_1.UUID_REGEX);
    if (match && match[1]) {
        return match[1];
    }
    return '';
};
exports.parseInboundUUID = parseInboundUUID;
exports.default = utils_1.formatDuration;
