"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleAuditScoreSelection = exports.handleFatalSelection = exports.getReasonForDelayedPayment = exports.findChoiceByValue = exports.validate = exports.generateInitialPayload = void 0;
const qualityAssuranceConstants_1 = require("../../features/qualityAssurance/qualityAssuranceConstants");
const auditConstants_1 = require("./auditConstants");
const generateInitialPayload = (instance, evaulationFields) => {
    const payload = {};
    if (instance) {
        Array.from(evaulationFields.keys()).forEach((key) => {
            const score = instance[key];
            payload[key] = score;
        });
    }
    else {
        evaulationFields.forEach((value, key) => {
            payload[key] = value.default;
        });
    }
    return payload;
};
exports.generateInitialPayload = generateInitialPayload;
const validate = (values) => {
    const errors = {};
    Object.entries(values).forEach(([field, value]) => {
        if (field === 'review_status' && (value === '' || Number(value) === qualityAssuranceConstants_1.NOT_REVIEWED)) {
            errors[field] = 'You are currently auditing this call record. Please select a valid status';
        }
    });
    return errors;
};
exports.validate = validate;
const findChoiceByValue = (choices, value) => {
    return choices.find((choice) => choice.value === value);
};
exports.findChoiceByValue = findChoiceByValue;
const getReasonForDelayedPayment = (callType, callDetailRecordAudit) => {
    let choice = null;
    if (callType === qualityAssuranceConstants_1.KEY_ACCOUNT) {
        choice = exports.findChoiceByValue(auditConstants_1.DELAYED_PAYMENT_REASON_KAM_CHOICES, callDetailRecordAudit.reason_for_delayed_payment);
    }
    else if (callType === qualityAssuranceConstants_1.COLLECTIONS) {
        choice = exports.findChoiceByValue(auditConstants_1.DELAYED_PAYMENT_REASON_CC_CHOICES, callDetailRecordAudit.reason_for_delayed_payment);
    }
    return choice;
};
exports.getReasonForDelayedPayment = getReasonForDelayedPayment;
const handleFatalSelection = (fields, handleChange, selectedKey) => {
    fields.forEach(([key, item]) => {
        // Make exception for review_status, tag and selected key.
        if (item.choices && key !== 'review_status' && key !== 'tag' && key !== selectedKey) {
            // Again, call the handleChange function with the current key in the loop and update to "0".
            // This is to allow the other audit fields to have the reflected updates.
            handleChange(key)('0');
        }
    });
};
exports.handleFatalSelection = handleFatalSelection;
const handleAuditScoreSelection = (selectedKey, selectedChoice, handleChange, setFatalConfirmationModalVisible, setSelectedKey) => {
    // Call the handleChange function with the key and value arguments
    handleChange(selectedKey)(selectedChoice);
    // If any fatal selection was made, make other selection a choice of zero except review_status
    if (selectedChoice === auditConstants_1.FATAL) {
        setSelectedKey(selectedKey);
        // Show warning modal for fatal update confirmation
        setFatalConfirmationModalVisible(true);
    }
};
exports.handleAuditScoreSelection = handleAuditScoreSelection;
