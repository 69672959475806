"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const styles = react_native_1.StyleSheet.create({
    modalView: {
        margin: 10,
        borderRadius: 20,
        elevation: 5,
        top: react_native_1.Dimensions.get('window').height * 0.4,
        right: 0,
        left: 0,
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
exports.default = styles;
