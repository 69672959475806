"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RED_FLAG_URL = exports.ESCALATE_TO_RECOVERY_URL = exports.ADD_CUSTOM_NOTES_API_PATH = exports.FLAG_AS_DO_NOT_CALL_ACTION_URL = exports.DELETE_INSTALMENT_ACTION_URL = exports.DROPPED_CALL_ACTION_URL = exports.LOG_CALL_ACTION_URL = exports.CUSTOM_ACTION_URL = exports.SEND_SMS_ACTION_URL = void 0;
const constants_1 = require("../../constants/constants");
exports.SEND_SMS_ACTION_URL = `actions/schedule/${constants_1.SCHEDULE_ID_TEMPLATE_TAG}/send_sms/`;
exports.CUSTOM_ACTION_URL = `actions/schedule/${constants_1.SCHEDULE_ID_TEMPLATE_TAG}/custom/`;
exports.LOG_CALL_ACTION_URL = `actions/schedule/${constants_1.SCHEDULE_ID_TEMPLATE_TAG}/log_call/`;
exports.DROPPED_CALL_ACTION_URL = `actions/schedule/${constants_1.SCHEDULE_ID_TEMPLATE_TAG}/dropped_call/`;
exports.DELETE_INSTALMENT_ACTION_URL = `actions/schedule/${constants_1.SCHEDULE_ID_TEMPLATE_TAG}/delete_instalment/`;
exports.FLAG_AS_DO_NOT_CALL_ACTION_URL = `actions/schedule/${constants_1.SCHEDULE_ID_TEMPLATE_TAG}/flag_as_do_not_call/`;
exports.ADD_CUSTOM_NOTES_API_PATH = `actions/schedule/${constants_1.SCHEDULE_ID_TEMPLATE_TAG}/custom_notes/`;
exports.ESCALATE_TO_RECOVERY_URL = `actions/schedule/${constants_1.SCHEDULE_ID_TEMPLATE_TAG}/escalate_to_field_recovery/`;
exports.RED_FLAG_URL = `actions/schedule/${constants_1.SCHEDULE_ID_TEMPLATE_TAG}/red_flag/`;
