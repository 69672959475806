"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const validate = (values) => {
    const errors = {};
    const customError = 'This field is required.';
    if (!values.current_password) {
        errors.current_password = customError;
    }
    if (!values.new_password) {
        errors.new_password = customError;
    }
    if (!values.repeat_new_password) {
        errors.repeat_new_password = customError;
    }
    else if (values.repeat_new_password !== values.new_password) {
        errors.repeat_new_password = "The new password and confirm password don't match";
    }
    return errors;
};
exports.default = validate;
