"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSelectedCampaign = exports.resetCampaignsState = exports.getCampaignMetricsOfficer = exports.getCampaign = exports.getCampaignMetrics = exports.getCampaigns = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const typesConstants_1 = require("../../constants/typesConstants");
const network_1 = require("../../utils/network");
const utils_1 = require("../../utils/utils");
const campaignConstants_1 = __importStar(require("./campaignConstants"));
const constants_1 = require("../../constants/constants");
exports.getCampaigns = toolkit_1.createAsyncThunk('campaign/getCampaigns', async ({ page, name, queue, is_active }, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        const queryParams = utils_1.encodeUrlParams({
            ...(page && { page: String(page) }),
            ...(name && { name__icontains: name }),
            ...(queue && { queue__icontains: queue }),
            ...(is_active && { is_active }),
        });
        const urlPath = `${campaignConstants_1.default}?${queryParams}`;
        return await network_1.get(urlPath, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        return network_1.handleDefaultError(err, rejectWithValue);
    }
});
exports.getCampaignMetrics = toolkit_1.createAsyncThunk('schedules/getCampaignMetrics', async ({ campaign_id, page, name, queue, officer_type }, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        const queryParams = utils_1.encodeUrlParams({
            ...(page && { page: String(page) }),
            ...(name && { name__icontains: name }),
            ...(queue && { queue__icontains: queue }),
            ...(officer_type && { officer_type }),
        });
        const url = campaignConstants_1.CAMPAIGN_METRICS_API_PATH.replace(constants_1.CAMPAIGN_ID_TEMPLATE_TAG, campaign_id);
        const urlPath = `${url}?${queryParams}`;
        return await network_1.get(urlPath, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        return network_1.handleDefaultError(err, rejectWithValue);
    }
});
exports.getCampaign = toolkit_1.createAsyncThunk('schedules/getCampaign', async ({ campaign_id }, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        const url = campaignConstants_1.CAMPAIGN_API_PATH.replace(constants_1.CAMPAIGN_ID_TEMPLATE_TAG, campaign_id);
        return await network_1.get(url, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        return network_1.handleDefaultError(err, rejectWithValue);
    }
});
exports.getCampaignMetricsOfficer = toolkit_1.createAsyncThunk('schedules/getCampaignMetricsOfficer', async (_, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        return await network_1.get(campaignConstants_1.CAMPAIGN_METRICS_OFFICER_API_PATH, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        return network_1.handleDefaultError(err, rejectWithValue);
    }
});
const initialState = {
    campaigns: [],
    officers: [],
    officerMetrics: null,
    errorMessage: null,
    selectedCampaign: null,
    loadingStatus: typesConstants_1.PENDING_STATUS,
    currentPage: null,
    nextPage: null,
    previousPage: null,
    totalPages: null,
    totalEntries: null,
    pageSize: 50,
};
const campaignsSlice = toolkit_1.createSlice({
    name: 'campaigns',
    initialState,
    reducers: {
        resetCampaignsState() {
            return initialState;
        },
        setSelectedCampaign(state, action) {
            return { ...state, selectedCampaign: action.payload };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exports.getCampaign.pending, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.getCampaign.fulfilled, (state, { payload }) => ({
            ...state,
            loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
            selectedCampaign: payload,
        }))
            .addCase(exports.getCampaign.rejected, (state, { payload }) => ({
            ...state,
            loadingStatus: typesConstants_1.FAILED_STATUS,
            errorMessage: utils_1.convertObjToPlainString(payload.errorObject || {}),
        }))
            .addCase(exports.getCampaigns.pending, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.getCampaigns.fulfilled, (state, { payload }) => ({
            ...state,
            loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
            campaigns: payload.results,
            currentPage: payload.current,
            nextPage: payload.next,
            previousPage: payload.previous,
            totalPages: payload.total_pages,
            totalEntries: payload.total_entries,
            pageSize: payload.page_size,
        }))
            .addCase(exports.getCampaigns.rejected, (state, { payload }) => ({
            ...state,
            loadingStatus: typesConstants_1.FAILED_STATUS,
            errorMessage: utils_1.convertObjToPlainString(payload.errorObject || {}),
        }))
            .addCase(exports.getCampaignMetrics.pending, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.getCampaignMetrics.fulfilled, (state, { payload }) => ({
            ...state,
            loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
            officers: payload.results,
            currentPage: payload.current,
            nextPage: payload.next,
            previousPage: payload.previous,
            totalPages: payload.total_pages,
            totalEntries: payload.total_entries,
            pageSize: payload.page_size,
        }))
            .addCase(exports.getCampaignMetrics.rejected, (state, { payload }) => ({
            ...state,
            loadingStatus: typesConstants_1.FAILED_STATUS,
            errorMessage: utils_1.convertObjToPlainString(payload.errorObject || {}),
        }))
            .addCase(exports.getCampaignMetricsOfficer.pending, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.getCampaignMetricsOfficer.fulfilled, (state, { payload }) => ({
            ...state,
            loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
            officerMetrics: payload,
        }))
            .addCase(exports.getCampaignMetricsOfficer.rejected, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.FAILED_STATUS,
        }));
    },
});
_a = campaignsSlice.actions, exports.resetCampaignsState = _a.resetCampaignsState, exports.setSelectedCampaign = _a.setSelectedCampaign;
exports.default = campaignsSlice.reducer;
