"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setQueueMember = exports.setQueueParams = exports.setQueueMemberMetrics = exports.resetSocketState = exports.newSocket = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const config_1 = require("../../../web/config");
const eventConstants_1 = __importStar(require("./eventConstants"));
const initialState = {
    socket: null,
    queueMemberMetrics: {
        Queue: '',
        Name: '',
        MemberName: '',
        Membership: '',
        Penalty: '',
        CallsTaken: '0',
        LastCall: '0',
        LastPause: '',
        InCall: '',
        Status: eventConstants_1.STATUS_UNAVAILABLE,
        Paused: '',
        PausedReason: '',
        Wrapuptime: '',
        VoipUserID: '',
    },
    queueParams: undefined,
    queueMembers: {},
};
const eventSlice = toolkit_1.createSlice({
    name: 'event',
    initialState,
    reducers: {
        newSocket: (state, { payload }) => {
            const { token } = payload;
            const socket = new WebSocket(`${config_1.SOCKET_URL}/ws/events/?token=${token}`, eventConstants_1.default);
            return { ...state, socket };
        },
        resetSocketState(state) {
            // Close the connection and reset the state.
            if (state.socket)
                state.socket.close();
            return {
                ...state,
                socket: null,
            };
        },
        setQueueMemberMetrics(state, { payload }) {
            return {
                ...state,
                queueMemberMetrics: payload,
            };
        },
        setQueueParams(state, { payload }) {
            return {
                ...state,
                queueParams: payload,
            };
        },
        setQueueMember(state, { payload }) {
            return {
                ...state,
                queueMembers: { ...state.queueMembers, [payload.VoipUserID]: payload },
            };
        },
    },
});
_a = eventSlice.actions, exports.newSocket = _a.newSocket, exports.resetSocketState = _a.resetSocketState, exports.setQueueMemberMetrics = _a.setQueueMemberMetrics, exports.setQueueParams = _a.setQueueParams, exports.setQueueMember = _a.setQueueMember;
exports.default = eventSlice.reducer;
