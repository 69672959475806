"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EXTENSIONS = exports.CREDIT_COLLECTIONS = exports.CALL_DETAIL_RECORD_COMMENT_PATH = void 0;
const constants_1 = require("../../constants/constants");
const typesConstants_1 = require("../../constants/typesConstants");
const CALL_LOG_PATH = 'quality-assurance/officer/list/';
exports.CALL_DETAIL_RECORD_COMMENT_PATH = `quality-assurance/comment/${constants_1.CALL_DETAIL_RECORD_ID}/`;
exports.CREDIT_COLLECTIONS = 'cc';
exports.EXTENSIONS = new Map([
    [typesConstants_1.OFFICER_TYPES_TELE_COLLECTION, exports.CREDIT_COLLECTIONS],
    [typesConstants_1.OFFICER_TYPES_CUSTOMER_SUPPORT, 'cs'],
    [typesConstants_1.OFFICER_TYPES_KEY_ACCOUNT, 'ka'],
    [typesConstants_1.OFFICER_TYPES_TELE_COLLECTION_ASSISTANT, 'csa'],
    [typesConstants_1.OFFICER_TYPES_PRE_QUALIFICATION, 'pq'],
    [typesConstants_1.OFFICER_TYPES_TELE_SALES, 'ts'],
]);
exports.default = CALL_LOG_PATH;
