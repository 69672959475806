"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const styles = react_native_1.StyleSheet.create({
    mobilePickerContainerStyle: {
        width: 280,
        marginLeft: 0,
    },
    select: {
        borderRadius: 5,
        marginLeft: 10,
        marginRight: 10,
        height: 65,
        fontFamily: 'Arial',
        fontSize: 16,
        padding: 12,
        width: '100%',
    },
    searchButton: {
        height: 60,
        marginLeft: -12,
        borderRadius: 0,
        marginBottom: -1,
    },
    searchBar: {
        width: 220,
        marginBottom: -1,
    },
    opacityFifty: {
        opacity: 0.5,
    },
    opacityEighty: {
        opacity: 0.8,
    },
    marginLeftNegativeSeven: {
        marginLeft: -7,
    },
    marginLeftZero: {
        marginLeft: 0,
    },
});
exports.default = styles;
